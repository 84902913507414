import React, { useEffect } from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import useRedux from 'hooks/useRedux'
import * as campanhaActions from 'store/modules/backoffice/campanha/actions'
import { FiBriefcase } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { CAMPANHA } from 'store/modules/backoffice/campanha/types'
import { SimpleTitleCard } from 'styles/global'
import { maskNumber, validarData } from 'util/masks'

const Campanha = () => {
  const { register, optionsSelect, invalidsTabDadosGerais, visualizacao } =
    useSelector<ApplicationState, CAMPANHA>((state) => state.campanha)

  const { dispatch } = useRedux()

  type Fields = keyof CAMPANHA['register']
  function onChange<T extends Fields>(
    value: T extends Fields ? CAMPANHA['register'][T] : never,
    field: T
  ) {
    dispatch(campanhaActions.setRegisterFields(value, field))
  }

  useEffect(() => {
    dispatch(campanhaActions.getTiposOperacaoSaga())
  }, [dispatch])

  return (
    <div className="d-flex flex-column row-gap-10">
      <SimpleTitleCard>
        <FiBriefcase className="icon" />
        Campanha
      </SimpleTitleCard>
      <Row>
        <Col className="d-flex flex-column justify-content-end" md={6}>
          <label className="label-12">Descrição*</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(e.target.value, 'descricao')
            }}
            value={register.descricao}
            invalid={invalidsTabDadosGerais.descricao}
            disabled={visualizacao}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column justify-content-start" md={6}>
          <label className="label-12">Tipo de operação</label>
          <CustomSelect
            options={optionsSelect.tiposOperacao}
            placeholder="Selecione"
            onChange={(e) => {
              onChange(e, 'tipoOperacaoId')
            }}
            value={register.tipoOperacaoId}
            accessorLabel="descricao"
            accessorValue="id"
            invalid={invalidsTabDadosGerais.tipoOperacaoId}
            disabled={visualizacao}
          />
        </Col>
        <Col className="d-flex flex-column justify-content-end" md={6}>
          <label className="label-12">Empresas/grupos*</label>
          <CustomSelect
            options={optionsSelect.empresasGrupos}
            isMulti
            placeholder="Selecione empresas/grupos"
            onChange={(e) => {
              onChange(e, 'empresasGrupo')
            }}
            value={register.empresasGrupo}
            accessorLabel="descricao"
            accessorValue="idGrupoELoja"
            invalid={invalidsTabDadosGerais.empresasGrupo}
            disabled={visualizacao || !!register.id}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Data início campanha*</label>
          <InputCustom
            type="date"
            onInput={validarData}
            onChange={(e) => onChange(e.target.value, 'dataInicioCampanha')}
            value={register.dataInicioCampanha}
            invalid={invalidsTabDadosGerais.dataInicioCampanha}
            disabled={visualizacao}
          />
        </Col>
        <Col className="d-flex flex-column justify-content-end" md={3}>
          <label className="label-12">Data final campanha*</label>
          <InputCustom
            type="date"
            onInput={validarData}
            onChange={(e) => {
              onChange(e.target.value, 'dataFimCampanha')
            }}
            value={register.dataFimCampanha}
            invalid={invalidsTabDadosGerais.dataFimCampanha}
            disabled={visualizacao}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-column justify-content-end" md={6}>
          <label className="label-12">Quantidade de clientes*</label>
          <InputCustom
            type="text"
            placeholder="Digite aqui"
            onChange={(e) => {
              onChange(Number(maskNumber(e.target.value)), 'quantidadeClientes')
            }}
            value={register.quantidadeClientes}
            invalid={invalidsTabDadosGerais.quantidadeClientes}
            disabled={visualizacao}
          />
        </Col>
      </Row>
    </div>
  )
}
export default Campanha
