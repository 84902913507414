import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  line-height: 1.5;
  border-bottom: 1px solid rgb(187, 188, 189);
`

export const Message = styled.h5`
  margin: 0;
  flex: 1;
  font-size: 16px;
  font-weight: normal;
  color: #343a40; /* Texto escuro */
`

export const ButtonClose = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  color: #495057; /* Cor inicial */
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: #f8d7da; /* Fundo vermelho suave */
    color: #721c24; /* Cor vermelha escura */
  }

  &:focus {
    outline: 2px solid #f5c6cb; /* Foco visível */
  }
`

export const BancoColumn = styled.td`
  min-width: 150px;
  font-weight: bold;
  color: #6c757d !important;
`

export const ProdutoColumn = styled.td`
  min-width: 200px;
  font-weight: bold;
`

export const MatriculaColumn = styled.td`
  min-width: 100px;
`

export const MensagemColumn = styled.td`
  color: #dc3545 !important;
  min-width: 300px;
`
