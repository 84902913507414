import React, { useEffect, useRef, useState } from 'react'
import SimpleCard from 'components/SimpleCard'
import { FiPackage, FiSmartphone, FiUser } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  Col,
  Collapse,
  Input,
  Row,
  TabContent,
  Table,
  TabPane
} from 'reactstrap'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import {
  AccordionContainer,
  ApresentacaoValores,
  ContainerButtonsBancos,
  Tab,
  Ul
} from 'views/atendimento/style'
import CardOutrosProdutos from '../CardOutrosProdutos'
import CardProduto from '../CardProduto'
import CardProdutosContratados from '../CardProdutosContratados'
import DetalhamentoProduto from '../DetalhamentoProduto'
import ResumoContratacao from '../Modals/ResumoContratacao'
import ValidaBeneficioBmg from '../ValidaBeneficioBmg'
import {
  maskDate,
  maskMoney,
  maskPhoneWithDDD,
  removeMaskMoney,
  removeMaskPhone
} from 'util/masks'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import { ProdutoSimulacao, SimulacaoCrefaz } from 'models/types'
import useRedux from 'hooks/useRedux'
import { toast } from 'react-toastify'
import LoadingBasic from '../LoadingBasic'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { AiOutlineReload } from 'react-icons/ai'
import logoBmg from 'assets/images/logo-banco-bmg-256.svg'
import logoItau from 'assets/images/logo-banco-itau-256.svg'
import classNames from 'classnames'
import { CreatableSelect } from 'components/Select/CreatableSelect'
import { Beneficio } from 'models/atendimento/types'
import loadingIcon from 'assets/svg/loading-icon.svg'
import PropostaIndicacao from '../Modals/PropostaIndicacao'
import PropostaIndicacaoManual from '../Modals/PropostaIndicacaoManual'
import Agregacao from '../Modals/Agregacao'
import ElegibilidadeRefin from '../Modals/ElegibilidadeRefin'
import { CardContratoRefinItau } from '../CardContratoRefinItau'
import CoberturaSeguroPrestamista from '../Modals/CoberturasSeguroPrestamista'
import CustomSelect from 'components/Select'
import SeguroSindnapi from '../Modals/SeguroSindnapi'
import { CardContratoPortabilidade } from '../CardContratoPortabilidade'

/**
 * Produtos
 * TabId = 2
 * **/

interface Props {
  index: number
}

const Produto = ({ index }: Props) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  const [sessaoIN100, setSessaoIN100] = useState(false)
  const [matriculasInss, setMatriculasInss] = useState<Beneficio[]>([])
  const [beneficioInssActive, setbeneficioInssActive] = useState<Beneficio>()
  const [telefoneIN100, setTelefoneIN100] = useState<string | null>('')
  const [canalSelected, setCanalSelected] = useState('WHATSAPP')
  const [isOpen, setIsOpen] = useState(false)
  const [warningsSimulation, setWarningsSimulation] = useState(false)
  const [
    isAccordionContratosInelegiveisOpen,
    setIsAccordionContratosInelegiveisOpen
  ] = useState<string>('')
  const toggleAccordionContratosInelegiveis = (id: string) => {
    if (isAccordionContratosInelegiveisOpen === id) {
      setIsAccordionContratosInelegiveisOpen('')
    } else {
      setIsAccordionContratosInelegiveisOpen(id)
    }
  }

  const toggle = () => setIsOpen(!isOpen)
  function updateBadge(index: number, count: number) {
    const badge = document.getElementById(`badge-simulacao-${index}`)
    if (badge) {
      badge.textContent = `${count}`
    }
  }

  const warningsSimulationToggle = () =>
    setWarningsSimulation(!warningsSimulation)

  useEffect(() => {
    const produtosSelecionados =
      atendimentosAbertos[index].tabProduto.produtosSelecionados

    const somatorioTotalProdutos = produtosSelecionados
      .map((produto) => {
        if (
          produto.servico === 5 ||
          produto.servico === 6 ||
          produto.servico === 7 ||
          produto.servico === 8 ||
          produto.servico === 12
        ) {
          if (!produto.flagSaqueAutorizado && !produto.flagSaqueParcelado) {
            return 0
          } else {
            return Number(removeMaskMoney(produto.valorSaque))
          }
        } else if (produto.servico === 2 && produto.clienteElegivel) {
          const codigoTabelaSelected = atendimentosAbertos[
            index
          ].tabProduto.listCodigoTabelaSelectedByCodigoSimulacao?.find(
            (item) => item.id === produto.codigoSimulacao
          )?.value

          const parcelasSelected = atendimentosAbertos[
            index
          ].tabProduto.listParcelasSelectedByCodigoSimulacao?.find(
            (item) => item.id === produto.codigoSimulacao
          )?.value

          const indexTabelaSelected =
            produto.listProdutos?.findIndex(
              (p) => p.codigo === codigoTabelaSelected
            ) ?? 0

          const indexParcelaSelected =
            produto.listProdutos?.[indexTabelaSelected]?.listParcelas.findIndex(
              (p) => p.parcelas === parcelasSelected
            ) ?? 0

          return (
            produto.listProdutos[indexTabelaSelected]?.listParcelas[
              indexParcelaSelected
            ]?.valorLiberado ?? 0
          )
        } else if (
          produto.servico === 3 &&
          produto.contratosPortabilidade?.some(
            (contrato) => contrato.clienteElegivel
          )
        ) {
          let valorTotalFinanciado = 0
          const { contratosPortabilidade } = produto

          if (contratosPortabilidade) {
            contratosPortabilidade
              .filter((contrato) => contrato.clienteElegivel)
              .forEach((contrato) => {
                contrato.listProdutosPortabilidade
                  .filter(
                    (produtoPortabilidade) => produtoPortabilidade.selecionado
                  )
                  .forEach((produtoPortabilidade) => {
                    valorTotalFinanciado +=
                      produtoPortabilidade.produtosAssociados
                        .filter(
                          (produtoAssociado) => produtoAssociado.selecionado
                        )
                        .reduce((acc, produtoAssociado) => {
                          return (
                            acc + (produtoAssociado.valorTotalFinanciado ?? 0)
                          )
                        }, 0)
                  })
              })
          }
          return valorTotalFinanciado
        } else if (produto.servico === 13) {
          let valor = 0
          for (const simulacao of produto.simulacoesCrefaz ?? []) {
            valor += simulacao.selecionados.valorSolicitado
          }
          return valor
        } else if (produto.servico === 1) {
          return produto.seguroPrestamistaEmprestimo &&
            produto.flagSeguroPrestamista
            ? produto.valorLiberado
            : produto.valorSolicitado
        } else {
          return produto.valorTotalFinanciado
        }
      })
      .reduce((acc, curr) => acc + curr, 0)

    dispatch(
      atendimentoActions.setSomatorioTotalProdutos(
        somatorioTotalProdutos,
        index
      )
    )
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    atendimentosAbertos[index].tabProduto.produtosSelecionados,
    dispatch,
    index,
    ...atendimentosAbertos[index].tabProduto.produtosSelecionados.flatMap(
      (produtoSelecionado) => [
        ...(produtoSelecionado.contratosPortabilidade?.flatMap(
          (contratoPortabilidade) => [
            ...(contratoPortabilidade.listProdutosPortabilidade?.flatMap(
              (produtoPortabilidade) => [
                produtoPortabilidade.selecionado,
                ...(produtoPortabilidade.produtosAssociados?.map(
                  (produtoAssociado) => produtoAssociado.selecionado
                ) || [])
              ]
            ) || [])
          ]
        ) || [])
      ]
    ),
    ...atendimentosAbertos[index].tabProduto.produtosSelecionados.flatMap(
      (produtoSelecionado) => [
        produtoSelecionado.valorTotalFinanciado,
        produtoSelecionado.flagSeguroPrestamista,
        produtoSelecionado.valorSolicitado,
        ...(produtoSelecionado.simulacoesCrefaz?.flatMap((simulacao) => [
          simulacao.selecionados.valorSolicitado,
          simulacao.selecionados.valorParcela,
          simulacao.selecionados.prazo
        ]) || [])
      ]
    ),
    atendimentosAbertos[index].tabProduto.listParcelasSelectedByCodigoSimulacao
  ])
  /* eslint-enable react-hooks/exhaustive-deps */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<any>(null)

  function selecionaProduto(e: ProdutoSimulacao) {
    let produtosAtuaisSelecionados = [
      ...atendimentosAbertos[index].tabProduto.produtosSelecionados
    ]
    if (produtosAtuaisSelecionados.find((produto) => produto.id === e.id)) {
      produtosAtuaisSelecionados =
        produtosAtuaisSelecionados.filter((produto) => produto.id !== e.id) ||
        []
    } else {
      produtosAtuaisSelecionados.push(e)
    }
    dispatch(
      atendimentoActions.setProdutosSelecionados(
        produtosAtuaisSelecionados,
        index
      )
    )
  }

  function selecionaProdutoCrefaz(e: SimulacaoCrefaz) {
    const { produtosSelecionados } = atendimentosAbertos[index].tabProduto
    const produtosAtuaisSelecionados = [...produtosSelecionados]
    const crefazIndex = produtosAtuaisSelecionados.findIndex(
      (produto) => produto.servico === 13
    )

    const produtoCrefaz = produtosAtuaisSelecionados[crefazIndex]
    const simulacoesCrefaz = produtoCrefaz?.simulacoesCrefaz || []

    const produtoJaSelecionado = simulacoesCrefaz.some(
      (simulacao) => simulacao.produtoId === e.produtoId
    )

    const produtosCrefazSelecionados = produtoJaSelecionado
      ? simulacoesCrefaz.filter(
          (simulacao) => simulacao.produtoId !== e.produtoId
        )
      : [...simulacoesCrefaz, e]

    dispatch(
      atendimentoActions.onCheckProdutoCrefaz(produtosCrefazSelecionados, index)
    )
  }

  function toggleModal() {
    dispatch(
      atendimentoActions.setInfosProduto(
        !atendimentosAbertos[index].tabProduto.modalResumoContratacao,
        'modalResumoContratacao',
        index
      )
    )
  }

  function toggleModalIndicacao() {
    dispatch(
      atendimentoActions.setInfosProduto(
        !atendimentosAbertos[index].tabProduto.modalIndicacao,
        'modalIndicacao',
        index
      )
    )
  }

  function toggleModalIndicacaoManual() {
    dispatch(
      atendimentoActions.setInfosProduto(
        !atendimentosAbertos[index].tabProduto.modalIndicacaoManual,
        'modalIndicacaoManual',
        index
      )
    )
  }

  function toggleModalAgregacao() {
    dispatch(
      atendimentoActions.setInfosProduto(
        !atendimentosAbertos[index].tabProduto.modalAgregacao,
        'modalAgregacao',
        index
      )
    )
  }

  function toggleModalElegibilidadeRefin() {
    dispatch(
      atendimentoActions.setInfosProduto(
        !atendimentosAbertos[index].tabProduto.modalInegibilidade,
        'modalInegibilidade',
        index
      )
    )
  }

  function cleanAndFocusTelefone() {
    setTelefoneIN100('')
    selectRef.current.focus()
  }

  function toggleModalSeguroPlanoPrestamista() {
    dispatch(
      atendimentoActions.setInfosProduto(
        !atendimentosAbertos[index].tabProduto.modalCoberturasSeguroPrestamista,
        'modalCoberturasSeguroPrestamista',
        index
      )
    )
  }

  function toggleModalSeguroSindnapi() {
    dispatch(
      atendimentoActions.setInfosProduto(
        !atendimentosAbertos[index].tabProduto.modalSeguroSindnapi,
        'modalSeguroSindnapi',
        index
      )
    )
  }

  const isCrefazInvalido = (produto: ProdutoSimulacao) =>
    produto.servico === 13 &&
    produto.simulacoesCrefaz?.some(
      (p) =>
        p.selecionados.convenioDados.some((c) => c.valor === null) ||
        p.selecionados.valorParcela === null ||
        p.selecionados.prazo === null
    )

  const getCrefazInvalidoIndex = () =>
    atendimentosAbertos[index].tabProduto.produtosSelecionados.findIndex(
      isCrefazInvalido
    )

  const getConvenioDadosCrefazInvalidoIndex = (crefazInvalidoIndex: number) =>
    atendimentosAbertos[index].tabProduto.produtosSelecionados[
      crefazInvalidoIndex
    ]?.simulacoesCrefaz?.findIndex(
      (simulacao) =>
        simulacao.selecionados.convenioDados.some((c) => c.valor === null) ||
        simulacao.selecionados.valorParcela === null ||
        simulacao.selecionados.prazo === null
    )

  const getCamposConvenioDadosCrefazInvalidos = (
    crefazInvalidoIndex: number
  ) => {
    const produtosSelecionados =
      atendimentosAbertos[index].tabProduto.produtosSelecionados[
        crefazInvalidoIndex
      ]
    if (!produtosSelecionados) {
      return []
    }
    const simulacoesCrefaz = produtosSelecionados.simulacoesCrefaz
    if (!simulacoesCrefaz) {
      return []
    }
    const camposInvalidos = simulacoesCrefaz.flatMap((simulacao) =>
      simulacao.selecionados.convenioDados
        .filter((c) => c.valor === null)
        .map((c) => c.descricao)
    )

    return camposInvalidos
  }
  useEffect(() => {
    if (atendimentosAbertos[index].tabProduto.beneficios.length !== 0) {
      dispatch(
        atendimentoActions.getProdutosContratadosSaga(
          atendimentosAbertos[index].cpf,
          index
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, atendimentosAbertos[index].tabProduto.beneficios])

  useEffect(() => {
    if (
      atendimentosAbertos[index].tabContato.infosChamada.telefones?.length ===
        0 &&
      !atendimentosAbertos[index].tabContato.enriquecido
    ) {
      dispatch(
        atendimentoActions.enriquecerDadosSaga(
          atendimentosAbertos[index].cpf,
          index,
          true
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setMatriculasInss(
      atendimentosAbertos[index].tabProduto.beneficios.filter((beneficio) =>
        beneficio.clientePerfil.descricao.includes('INSS')
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabProduto.beneficios])

  useEffect(() => {
    if (
      !matriculasInss.find(
        (matricula) => matricula.numero === beneficioInssActive?.numero
      ) &&
      matriculasInss?.length > 0
    ) {
      setbeneficioInssActive(matriculasInss[0])
    } else {
      const indiceMatriculaAtual = matriculasInss.findIndex(
        (matricula) => matricula.numero === beneficioInssActive?.numero
      )
      if (indiceMatriculaAtual !== -1) {
        setbeneficioInssActive(matriculasInss[indiceMatriculaAtual])
      }
    }
  }, [matriculasInss, beneficioInssActive])

  function iniciarContratacao() {
    if (atendimentosAbertos[index].tabProduto.produtosSelecionados.length > 0) {
      if (
        atendimentosAbertos[index].tabProduto.produtosSelecionados.some(
          (produto) =>
            (produto.servico === 5 ||
              produto.servico === 6 ||
              produto.servico === 7 ||
              produto.servico === 8 ||
              produto.servico === 12) &&
            (Number(removeMaskMoney(produto.valorSaque)) >
              produto.valorSaqueMaximo ||
              Number(removeMaskMoney(produto.valorSaque)) <
                produto.valorSaqueMinimo)
        )
      ) {
        dispatch(atendimentoActions.setInvalidContratacao(true, index))
        return toast.warn(
          'Insira os valores corretamente para finalizar a contratação'
        )
      }
      if (
        atendimentosAbertos[index].tabProduto.produtosSelecionados.some(
          isCrefazInvalido
        )
      ) {
        dispatch(atendimentoActions.setInvalidContratacao(true, index))

        const crefazInvalidoIndex = getCrefazInvalidoIndex()
        const convenioDadosCrefazInvalidoIndex =
          getConvenioDadosCrefazInvalidoIndex(crefazInvalidoIndex)

        const produtoSelecionado =
          atendimentosAbertos[index].tabProduto.produtosSelecionados[
            crefazInvalidoIndex
          ]

        if (!atendimentosAbertos[index].tabProduto.detalhamentoIsOpen) {
          dispatch(atendimentoActions.toggleDetalhamento(index))
        }

        if (atendimentosAbertos[index].tabProduto.detalhamentoIsOpen) {
          dispatch(
            atendimentoActions.selectProduto(
              {
                servico: produtoSelecionado.servico,
                matricula: produtoSelecionado.matricula ?? '',
                produtoCrefazId:
                  produtoSelecionado.simulacoesCrefaz?.[
                    convenioDadosCrefazInvalidoIndex ?? 0
                  ].produtoId
              },
              index
            )
          )
        }

        const camposInvalidos =
          getCamposConvenioDadosCrefazInvalidos(crefazInvalidoIndex)
        return toast.warn(
          <div className="d-flex flex-column">
            <span>
              Insira os dados corretamente para finalizar a contratação do
              Crefaz:
            </span>
            <ul className="d-flex flex-column">
              {camposInvalidos.map((campo, index) => (
                <li key={index}>{campo}</li>
              ))}
            </ul>
          </div>
        )
      }
      if (
        atendimentosAbertos[index].tabProduto.produtosSelecionados.some(
          (produto) =>
            produto.servico === 3 &&
            produto.contratosPortabilidade
              ?.filter(
                (contratoPortabilidade) => contratoPortabilidade.clienteElegivel
              )
              ?.some((contratoPortabilidade) =>
                contratoPortabilidade.listProdutosPortabilidade
                  .filter(
                    (produtoPortabilidade) => produtoPortabilidade.selecionado
                  )
                  .some((produtoAssociado) =>
                    produtoAssociado.produtosAssociados
                      .filter(
                        (produtoAssociado) => produtoAssociado.selecionado
                      )
                      .some((produto) => produto.valorTotalFinanciado === 0)
                  )
              )
        ) ||
        atendimentosAbertos[index].tabProduto.produtosSelecionados.some(
          (produto) => {
            if (produto.servico === 3) {
              const contratosElegiveis = produto.contratosPortabilidade?.filter(
                (contrato) => contrato.clienteElegivel
              )

              return contratosElegiveis?.every((contrato) => {
                const todosProdutosNaoSelecionados =
                  contrato.listProdutosPortabilidade.every(
                    (produtoPortabilidade) => !produtoPortabilidade.selecionado
                  )

                if (todosProdutosNaoSelecionados) {
                  return true
                }

                const produtosSelecionados =
                  contrato.listProdutosPortabilidade.filter(
                    (produtoPortabilidade) => produtoPortabilidade.selecionado
                  )

                const produtosAssociadosNaoSelecionados =
                  produtosSelecionados.every((produtoPortabilidade) =>
                    produtoPortabilidade.produtosAssociados.every(
                      (produtoAssociado) => !produtoAssociado.selecionado
                    )
                  )

                if (produtosAssociadosNaoSelecionados) {
                  return true
                }

                return false
              })
            }
          }
        )
      ) {
        dispatch(atendimentoActions.setInvalidContratacao(true, index))
        return toast.warn(
          'Produtos de portabilidade com valor igual a zero não podem ser contratados!'
        )
      } else {
        dispatch(atendimentoActions.setInvalidContratacao(false, index))
        return dispatch(
          atendimentoActions.validacaoContratacaoSaga(
            atendimentosAbertos[index].cpf,
            index
          )
        )
      }
    }
    return toast.warn('Não há produtos selecionados!')
  }

  function atualizaInformacoes() {
    dispatch(atendimentoActions.getPerfilClienteSaga(index))
    dispatch(
      atendimentoActions.getTelefonesSaga(atendimentosAbertos[index].cpf, index)
    )
  }

  function realizarSimulacao() {
    const notificationContainer = document.getElementById(
      `notificacao-simulacao-${index}`
    )
    if (notificationContainer) {
      notificationContainer.innerHTML = ''
      updateBadge(index, 0)
    }

    dispatch(
      atendimentoActions.getPropostasCrmSaga(
        atendimentosAbertos[index].cpf,
        index
      )
    )
    dispatch(
      atendimentoActions.getMatriculasModalIndicacaoSaga(
        atendimentosAbertos[index].cpf,
        index
      )
    )
  }

  function getCardProdutos() {
    const cardsRefin: string[] = []

    return atendimentosAbertos[index].tabProduto.produtosDisponiveis?.map(
      (produto, i) => {
        if (
          produto.servico === 2 &&
          cardsRefin.some((p) => p === produto.matricula)
        )
          return

        if (
          produto.servico === 2 &&
          !cardsRefin.some((p) => p === produto.matricula)
        ) {
          cardsRefin.push(produto.matricula)
        }

        return (
          <CardProduto
            key={i}
            produto={produto}
            onCheck={(e) => {
              selecionaProduto(e)
            }}
            onCheckCrefaz={(e) => {
              selecionaProdutoCrefaz(e)
            }}
            checked={
              !!atendimentosAbertos[
                index
              ].tabProduto?.produtosSelecionados?.find(
                (produtoSelecionado) => produtoSelecionado.id === produto.id
              )
            }
            index={index}
          />
        )
      }
    )
  }

  const contratosRefinItauInelegiveis = atendimentosAbertos[
    index
  ].tabProduto?.produtosDisponiveis.map((produto) => {
    if (
      produto.servico === 2 &&
      !produto.clienteElegivel &&
      produto !== undefined
    ) {
      return produto
    }
    return
  })

  const contratosPortabilidadeInelegiveis = atendimentosAbertos[
    index
  ].tabProduto?.produtosDisponiveis.map((produto) => {
    if (
      produto.servico === 3 &&
      produto.contratosPortabilidade?.some(
        (contrato) => !contrato.clienteElegivel
      ) &&
      produto !== undefined
    ) {
      return produto
    }
    return
  })

  return (
    <TabPane tabId={2}>
      <div className="container-fluid">
        <Row className="mb-2 mt-4" xl={12}>
          <Col
            md={6}
            className="d-flex justify-content-center justify-content-md-start justify-content-sm-center"
          >
            <div
              onClick={() => toggleModalIndicacaoManual()}
              className="btn-outline-purple justify-content-center"
              style={{ fontSize: '0.875rem' }}
            >
              Criar Indicação
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex justify-content-center justify-content-md-end justify-content-sm-center"
          >
            <div
              onClick={atualizaInformacoes}
              className="btn-outline-purple justify-content-center"
              style={{ fontSize: '0.875rem' }}
            >
              <AiOutlineReload /> Atualizar Informações
            </div>
          </Col>
        </Row>
      </div>
      <SimpleCard className="d-flex flex-column row-gap-20">
        {atendimentosAbertos[index].tabProduto.beneficios.some(
          (beneficio) =>
            beneficio.clientePerfil.descricao.toLocaleUpperCase() === 'INSS'
        ) ? (
          <>
            <div className="d-flex justify-content-between">
              <SimpleTitleCard>
                <FiSmartphone className="icon" /> Validação IN100
              </SimpleTitleCard>
              {sessaoIN100 ? (
                <BiChevronUp
                  onClick={() => setSessaoIN100(false)}
                  className="toggler"
                />
              ) : (
                <BiChevronDown
                  onClick={() => setSessaoIN100(true)}
                  className="toggler"
                />
              )}
            </div>
            <Collapse isOpen={sessaoIN100}>
              <ContainerButtonsBancos>
                <Input
                  type="radio"
                  name={'banco' + index}
                  id={'bmg' + index}
                  value="bmg"
                  checked={
                    atendimentosAbertos[index].tabProduto
                      .bancoValidacaoSelected === 'bmg'
                  }
                  onChange={(e) => {
                    dispatch(
                      atendimentoActions.setInfosProduto(
                        e.target.value,
                        'bancoValidacaoSelected',
                        index
                      )
                    )
                  }}
                />
                <label
                  className="labelBmg"
                  role="button"
                  htmlFor={'bmg' + index}
                >
                  <img src={logoBmg} />
                </label>
                <Input
                  type="radio"
                  name={'banco' + index}
                  id={'itau' + index}
                  value="itau"
                  checked={
                    atendimentosAbertos[index].tabProduto
                      .bancoValidacaoSelected === 'itau'
                  }
                  onChange={(e) => {
                    dispatch(
                      atendimentoActions.setInfosProduto(
                        e.target.value,
                        'bancoValidacaoSelected',
                        index
                      )
                    )
                  }}
                />
                <label
                  className="labelItau"
                  role="button"
                  htmlFor={'itau' + index}
                >
                  <img src={logoItau} />
                </label>
              </ContainerButtonsBancos>
              <Ul style={{ display: 'flex', paddingLeft: '0' }}>
                {matriculasInss.map((matricula, i) => {
                  return (
                    <Tab key={i}>
                      <a
                        onClick={() => {
                          setbeneficioInssActive(matricula)
                        }}
                        className={classNames(
                          beneficioInssActive?.numero === matricula.numero &&
                            'active'
                        )}
                      >
                        <span>{matricula.numero}</span>
                      </a>
                    </Tab>
                  )
                })}
              </Ul>
              <TabContent
                activeTab={
                  atendimentosAbertos[index].tabProduto.bancoValidacaoSelected
                }
              >
                <TabPane tabId="bmg">
                  <span>
                    O Banco BMG irá enviar um SMS para o número informado.
                  </span>
                  <Row className="d-flex align-items-stretch mt-3">
                    <Col className="d-flex align-items-center" xl={5}>
                      <div className="d-flex align-items-end column-gap-15 mb-3">
                        <div className="d-flex flex-column">
                          <label className="label-12">Telefone</label>
                          <CreatableSelect
                            refSelect={selectRef}
                            isClearable
                            options={
                              atendimentosAbertos[index].tabProduto
                                .telefonesClienteIN100
                            }
                            accessorValue="value"
                            accessorLabel="label"
                            placeholder="Ex: (99) 99999-9999"
                            onChange={(e) => {
                              if (e) {
                                setTelefoneIN100(maskPhoneWithDDD(e))
                              } else {
                                setTelefoneIN100(null)
                              }
                            }}
                            onInputChange={(e: string) => {
                              return maskPhoneWithDDD(e)
                            }}
                            onCreateOption={(e) => {
                              if (removeMaskPhone(e).length === 11) {
                                dispatch(
                                  atendimentoActions.setInfosProduto(
                                    [
                                      ...atendimentosAbertos[index].tabProduto
                                        .telefonesClienteIN100,
                                      { value: e, label: e }
                                    ],
                                    'telefonesClienteIN100',
                                    index
                                  )
                                )
                                setTelefoneIN100(maskPhoneWithDDD(e))
                              } else {
                                toast.warn(
                                  'Telefone inválido, preencha corretamente!'
                                )
                              }
                            }}
                            value={telefoneIN100}
                          />
                        </div>
                        <CustomButton
                          letterSpacing={2}
                          color="#fff"
                          justifyContent="center"
                          width={100}
                          onClick={() => {
                            if (telefoneIN100?.length === 15) {
                              dispatch(
                                atendimentoActions.inserirSolicitacaoIN100Saga(
                                  atendimentosAbertos[index].cpf,
                                  telefoneIN100 || '',
                                  beneficioInssActive?.numero || '',
                                  index
                                )
                              )
                            } else {
                              toast.warn(
                                'Necessário selecionar um número de telefone'
                              )
                            }
                          }}
                        >
                          Enviar
                        </CustomButton>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-stretch" xl={5}>
                      <ValidaBeneficioBmg
                        cpf={atendimentosAbertos[index].cpf}
                        matricula={beneficioInssActive?.numero || ''}
                        onResend={() => {
                          cleanAndFocusTelefone()
                        }}
                        onValidate={(e, matricula) => {
                          dispatch(
                            atendimentoActions.validacaoIN100Saga(
                              atendimentosAbertos[index].cpf,
                              e,
                              matricula
                            )
                          )
                        }}
                      />
                    </Col>
                    <Col
                      className="d-flex align-items-center justify-content-center"
                      xl={2}
                    >
                      <div className="d-flex flex-column align-items-center row-gap-20 mb-3">
                        <CustomButton
                          letterSpacing={2}
                          color="#fff"
                          justifyContent="center"
                          width={150}
                          onClick={() => {
                            dispatch(
                              atendimentoActions.validaStatusIN100Saga(
                                atendimentosAbertos[index].cpf,
                                beneficioInssActive?.numero || '',
                                index,
                                matriculasInss.findIndex(
                                  (beneficio) =>
                                    beneficio.numero ===
                                    beneficioInssActive?.numero
                                )
                              )
                            )
                          }}
                        >
                          {atendimentosAbertos[index].tabProduto.beneficios?.[
                            matriculasInss.findIndex(
                              (beneficio) =>
                                beneficio.numero === beneficioInssActive?.numero
                            )
                          ]?.loading ? (
                            <>
                              <img
                                src={loadingIcon}
                                alt="Loading sistema"
                                width="40"
                              />
                              Analisando
                            </>
                          ) : (
                            'Verificar'
                          )}
                        </CustomButton>
                        <div className="d-flex flex-column align-items-center">
                          <label className="label-12 text-center">
                            Última atualização
                          </label>
                          <span className="mt-1 text-center">
                            {beneficioInssActive?.dataRespostaIn100
                              ? maskDate(
                                  beneficioInssActive?.dataRespostaIn100,
                                  'DD/MM/YYYY'
                                )
                              : 'Data indisponível'}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <div className="linha-horizontal" />
                  </Row>
                </TabPane>
                <TabPane tabId="itau">
                  <span>
                    O Banco Itaú irá enviar uma mensagem para o número
                    informado.
                  </span>
                  <Row className="d-flex align-items-stretch mt-3">
                    <Col
                      className="d-flex align-items-center column-gap-15"
                      xl={10}
                    >
                      <div className="d-flex align-items-end column-gap-15 mb-3">
                        <div className="d-flex flex-column">
                          <label className="label-12">Telefone</label>
                          <CreatableSelect
                            isClearable
                            options={
                              atendimentosAbertos[index].tabProduto
                                .telefonesClienteIN100
                            }
                            accessorValue="value"
                            accessorLabel="label"
                            placeholder="Ex: (99) 99999-9999"
                            onChange={(e) => {
                              setTelefoneIN100(e)
                            }}
                            onInputChange={(e: string) => {
                              return maskPhoneWithDDD(e)
                            }}
                            onCreateOption={(e) => {
                              if (removeMaskPhone(e).length === 11) {
                                dispatch(
                                  atendimentoActions.setInfosProduto(
                                    [
                                      ...atendimentosAbertos[index].tabProduto
                                        .telefonesClienteIN100,
                                      { value: e, label: e }
                                    ],
                                    'telefonesClienteIN100',
                                    index
                                  )
                                )
                                setTelefoneIN100(maskPhoneWithDDD(e))
                              } else {
                                toast.warn(
                                  'Telefone inválido, preencha corretamente!'
                                )
                              }
                            }}
                            value={telefoneIN100}
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <label className="label-12">Canal</label>
                          <CustomSelect
                            options={[
                              { label: 'Whatsapp', value: 'WHATSAPP' },
                              { label: 'SMS', value: 'SMS' }
                            ]}
                            onChange={(e) => {
                              setCanalSelected(e)
                            }}
                            accessorLabel="label"
                            accessorValue="value"
                            value={canalSelected}
                            isClearable={false}
                          />
                        </div>
                        <CustomButton
                          letterSpacing={2}
                          color="#fff"
                          justifyContent="center"
                          width={100}
                          onClick={() => {
                            if (telefoneIN100?.length === 15) {
                              dispatch(
                                atendimentoActions.consentimentoItauSaga(
                                  atendimentosAbertos[index].cpf,
                                  telefoneIN100 || '',
                                  beneficioInssActive?.numero || '',
                                  canalSelected || ''
                                )
                              )
                            } else {
                              toast.warn(
                                'Necessário selecionar um número de telefone'
                              )
                            }
                          }}
                        >
                          Enviar
                        </CustomButton>
                      </div>
                    </Col>
                    <Col
                      className="d-flex align-items-center justify-content-center"
                      xl={2}
                    >
                      <div className="d-flex flex-column align-items-start align-items-md-center row-gap-20 mb-3">
                        <CustomButton
                          letterSpacing={2}
                          color="#fff"
                          justifyContent="center"
                          width={150}
                          onClick={() => {
                            if (telefoneIN100?.length === 15) {
                              dispatch(
                                atendimentoActions.consultaConsentimentoItau(
                                  atendimentosAbertos[index].cpf,
                                  beneficioInssActive?.numero || '',
                                  index,
                                  matriculasInss.findIndex(
                                    (beneficio) =>
                                      beneficio.numero ===
                                      beneficioInssActive?.numero
                                  )
                                )
                              )
                            } else {
                              toast.warn(
                                'Necessário selecionar um número de telefone'
                              )
                            }
                          }}
                        >
                          Verificar
                        </CustomButton>
                        <div className="d-flex flex-column align-items-center">
                          <label className="label-12 text-center">
                            Última atualização
                          </label>
                          <span className="mt-1 text-center">
                            {beneficioInssActive?.dataRespostaIn100
                              ? maskDate(
                                  beneficioInssActive?.dataRespostaIn100,
                                  'DD/MM/YYYY'
                                )
                              : 'Data indisponível'}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <div className="linha-horizontal mt-2" />
                  </Row>
                </TabPane>
              </TabContent>
            </Collapse>
          </>
        ) : (
          <></>
        )}
        <Row className="d-flex flex-column align-items-center gap-3">
          <div className="linha-horizontal" />
          <Col md={4}>
            <CustomButton
              onClick={() => realizarSimulacao()}
              letterSpacing={2}
              width={'100%'}
              className="text-center"
            >
              REALIZAR SIMULAÇÃO
            </CustomButton>
          </Col>
          <div className="linha-horizontal" />
        </Row>
        <ApresentacaoValores>
          <Col lg={1}>
            <FiUser className="icon" />
          </Col>
          <Col className="d-flex flex-column row-gap-10" md={5}>
            <label>Estas são as propostas selecionadas para:</label>
            <span>{atendimentosAbertos[index].tabContato.nome}</span>
          </Col>
          <Col className="d-flex align-items-start" md={3}>
            <span className="valor-contratacao">
              R$
              {maskMoney(
                atendimentosAbertos[
                  index
                ].tabProduto.somatorioTotalProdutos.toFixed(2)
              )}
            </span>
          </Col>
          <Col lg={3}>
            <CustomButton
              onClick={() => iniciarContratacao()}
              letterSpacing={2}
              width={'100%'}
            >
              CONTRATAR
            </CustomButton>
          </Col>
        </ApresentacaoValores>
        <Row className="d-flex row-gap-20 flex-row-reverse">
          {atendimentosAbertos[index].tabProduto?.loadingSimulacao ? (
            <LoadingBasic
              mainText="Estamos realizando a simulação do cliente"
              secondaryText="Aguarde..."
            />
          ) : (
            <>
              {atendimentosAbertos[index].tabProduto?.detalhamentoIsOpen && (
                <Col>
                  {atendimentosAbertos[
                    index
                  ].tabProduto?.produtosDisponiveis.map((produto, i) => {
                    const produtoSelected =
                      atendimentosAbertos[index].tabProduto?.produtoSelected
                    const isSameServico =
                      produto.servico === produtoSelected?.servico
                    const isSameMatricula =
                      produto.matricula === produtoSelected?.matricula
                    const isCrefaz = produto.servico === 13
                    const hasProdutoCrefazId =
                      !!produtoSelected?.produtoCrefazId
                    if (
                      (isSameServico && isSameMatricula) ||
                      (isSameServico && isCrefaz && hasProdutoCrefazId)
                    ) {
                      if (
                        (produto.servico === 2 && !produto.clienteElegivel) ||
                        (produto.servico === 3 &&
                          !produto.contratosPortabilidade?.some(
                            (contrato) => contrato.clienteElegivel
                          ))
                      ) {
                        return <></>
                      }

                      if (produto.servico === 3) {
                        const contratosElegiveis =
                          produto.contratosPortabilidade
                            ?.map((contrato, contratoIndex) => ({
                              ...contrato,
                              contratoIndex
                            }))
                            .filter((contrato) => contrato.clienteElegivel)
                        if (
                          contratosElegiveis &&
                          contratosElegiveis.length > 0
                        ) {
                          return contratosElegiveis.map((contrato) => {
                            const parcelasSelected = atendimentosAbertos[
                              index
                            ].tabProduto.listParcelasSelectedByCodigoSimulacao?.find(
                              (item) => item.id === produto.codigoSimulacao
                            )?.value

                            return (
                              <CardContratoPortabilidade
                                key={`${i}-${contrato.contratoIndex}`}
                                codigoBanco={contrato.codigoBanco}
                                numeroProposta={contrato?.numeroProposta ?? ''}
                                clienteElegivel={contrato?.clienteElegivel}
                                valorParcela={contrato?.valorParcela}
                                quantidadeTotalParcela={
                                  contrato?.quantidadeTotalParcela
                                }
                                quantidadeParcelaEmAberto={
                                  contrato?.quantidadeParcelaEmAberto
                                }
                                taxaOrigem={contrato?.taxaOrigem}
                                valorSaldoDevedor={contrato?.valorSaldoDevedor}
                                erro={contrato?.erro}
                                listProdutosPortabilidade={
                                  contrato?.listProdutosPortabilidade
                                }
                                index={index}
                                parcelasSelected={parcelasSelected ?? 0}
                                propostaId={contrato?.propostaId ?? ''}
                                simulacao={produto}
                                id={produto.id}
                                indexContrato={contrato.contratoIndex}
                              />
                            )
                          })
                        }
                      }

                      if (produto.servico === 2 && produto.clienteElegivel) {
                        const contrato = produto.contratoRefinanciamento

                        if (contrato) {
                          const motivosInelegibilidade =
                            contrato.dadosRefinanciamento?.motivosInelegibilidade?.map(
                              (motivo) => motivo.mensagem
                            )

                          const erros = produto.erros

                          const parcelasSelected = atendimentosAbertos[
                            index
                          ].tabProduto.listParcelasSelectedByCodigoSimulacao?.find(
                            (item) => item.id === produto.codigoSimulacao
                          )?.value

                          const codigoTabelaSelected = atendimentosAbertos[
                            index
                          ].tabProduto.listCodigoTabelaSelectedByCodigoSimulacao?.find(
                            (item) => item.id === produto.codigoSimulacao
                          )?.value

                          return (
                            <CardContratoRefinItau
                              id={produto.id}
                              key={i}
                              numeroOperacaoCreditoExterno={
                                contrato.numeroOperacaoCreditoExterno
                              }
                              permiteAgregacaoRefin={
                                produto.permiteAgregacaoRefin
                              }
                              clienteElegivel={produto.clienteElegivel}
                              valorParcela={contrato.valorParcela}
                              saldoDevedor={contrato.valorSaldoDevedor}
                              quantidadeTotalParcelas={
                                contrato.quantidadeTotalParcela
                              }
                              quantidadeParcelaPaga={
                                contrato.quantidadeParcelaPaga
                              }
                              listProdutos={produto.listProdutos}
                              taxaOrigem={contrato.percentualTaxaEfetivaMensal}
                              index={index}
                              errors={[
                                ...(motivosInelegibilidade
                                  ? motivosInelegibilidade
                                  : []),
                                ...(erros ? erros : [])
                              ]}
                              simulacao={produto}
                              codigoTabelaSelected={codigoTabelaSelected ?? 0}
                              parcelasSelected={parcelasSelected ?? 0}
                            />
                          )
                        }
                      }

                      return (
                        <DetalhamentoProduto
                          key={i}
                          cpf={atendimentosAbertos[index].cpf}
                          produto={produto}
                          produtoCrefazId={
                            atendimentosAbertos[index].tabProduto
                              ?.produtoSelected.produtoCrefazId
                          }
                          index={index}
                        />
                      )
                    }
                  })}
                  {contratosRefinItauInelegiveis.some(
                    (produto) =>
                      produto &&
                      produto.servico ===
                        atendimentosAbertos[index].tabProduto?.produtoSelected
                          .servico &&
                      produto.matricula ===
                        atendimentosAbertos[index].tabProduto?.produtoSelected
                          .matricula
                  ) && (
                    <AccordionContainer>
                      <Accordion
                        open={isAccordionContratosInelegiveisOpen}
                        //  eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //  @ts-ignore
                        //  https://github.com/reactstrap/reactstrap/issues/2165
                        toggle={toggleAccordionContratosInelegiveis}
                      >
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            Contratos indisponíveis para refinanciamento
                          </AccordionHeader>
                          <AccordionBody accordionId="1" className="p0">
                            {contratosRefinItauInelegiveis?.map(
                              (produto, i) => {
                                if (
                                  produto &&
                                  produto.servico ===
                                    atendimentosAbertos[index].tabProduto
                                      ?.produtoSelected.servico &&
                                  produto.matricula ===
                                    atendimentosAbertos[index].tabProduto
                                      ?.produtoSelected.matricula
                                ) {
                                  const contrato =
                                    produto?.contratoRefinanciamento

                                  if (contrato) {
                                    const motivosInelegibilidade =
                                      contrato.dadosRefinanciamento?.motivosInelegibilidade?.map(
                                        (motivo) => motivo.mensagem
                                      )

                                    const erros = produto.erros

                                    const parcelasSelected =
                                      atendimentosAbertos[
                                        index
                                      ].tabProduto.listParcelasSelectedByCodigoSimulacao?.find(
                                        (item) =>
                                          item.id === produto.codigoSimulacao
                                      )?.value

                                    const codigoTabelaSelected =
                                      atendimentosAbertos[
                                        index
                                      ].tabProduto.listCodigoTabelaSelectedByCodigoSimulacao?.find(
                                        (item) =>
                                          item.id === produto.codigoSimulacao
                                      )?.value

                                    return (
                                      <CardContratoRefinItau
                                        id={produto.id}
                                        key={i}
                                        numeroOperacaoCreditoExterno={
                                          contrato.numeroOperacaoCreditoExterno
                                        }
                                        permiteAgregacaoRefin={
                                          produto.permiteAgregacaoRefin
                                        }
                                        clienteElegivel={
                                          produto.clienteElegivel
                                        }
                                        valorParcela={contrato.valorParcela}
                                        saldoDevedor={
                                          contrato.valorSaldoDevedor
                                        }
                                        quantidadeTotalParcelas={
                                          contrato.quantidadeTotalParcela
                                        }
                                        quantidadeParcelaPaga={
                                          contrato.quantidadeParcelaPaga
                                        }
                                        listProdutos={produto.listProdutos}
                                        taxaOrigem={
                                          contrato.percentualTaxaEfetivaMensal
                                        }
                                        index={index}
                                        errors={[
                                          ...(motivosInelegibilidade
                                            ? motivosInelegibilidade
                                            : []),
                                          ...(erros ? erros : [])
                                        ]}
                                        simulacao={produto}
                                        codigoTabelaSelected={
                                          codigoTabelaSelected ?? 0
                                        }
                                        parcelasSelected={parcelasSelected ?? 0}
                                      />
                                    )
                                  }
                                }
                              }
                            )}
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>
                    </AccordionContainer>
                  )}
                  {contratosPortabilidadeInelegiveis.some(
                    (produto) =>
                      produto &&
                      produto.servico ===
                        atendimentosAbertos[index].tabProduto?.produtoSelected
                          .servico &&
                      produto.matricula ===
                        atendimentosAbertos[index].tabProduto?.produtoSelected
                          .matricula
                  ) && (
                    <AccordionContainer>
                      <Accordion
                        open={isAccordionContratosInelegiveisOpen}
                        //  eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //  @ts-ignore
                        //  https://github.com/reactstrap/reactstrap/issues/2165
                        toggle={toggleAccordionContratosInelegiveis}
                      >
                        <AccordionItem>
                          <AccordionHeader targetId="2">
                            Contratos indisponíveis para portabilidade
                          </AccordionHeader>
                          <AccordionBody accordionId="2" className="p0">
                            {contratosPortabilidadeInelegiveis?.map(
                              (produto, i) => {
                                const contratosInaptos =
                                  produto?.contratosPortabilidade?.filter(
                                    (contrato) => !contrato.clienteElegivel
                                  )

                                if (
                                  produto &&
                                  produto.servico ===
                                    atendimentosAbertos[index].tabProduto
                                      ?.produtoSelected.servico &&
                                  produto.matricula ===
                                    atendimentosAbertos[index].tabProduto
                                      ?.produtoSelected.matricula &&
                                  (contratosInaptos?.length ?? 0) > 0
                                ) {
                                  return (
                                    <div key={i}>
                                      {contratosInaptos?.map(
                                        (contrato, contratoIndex) => {
                                          const parcelasSelected =
                                            atendimentosAbertos[
                                              index
                                            ].tabProduto.listParcelasSelectedByCodigoSimulacao?.find(
                                              (item) =>
                                                item.id ===
                                                produto.codigoSimulacao
                                            )?.value

                                          return (
                                            <CardContratoPortabilidade
                                              key={`${i}-${contratoIndex}`}
                                              codigoBanco={contrato.codigoBanco}
                                              numeroProposta={
                                                contrato.numeroProposta
                                              }
                                              clienteElegivel={
                                                contrato.clienteElegivel
                                              }
                                              valorParcela={
                                                contrato.valorParcela
                                              }
                                              quantidadeTotalParcela={
                                                contrato.quantidadeTotalParcela
                                              }
                                              quantidadeParcelaEmAberto={
                                                contrato.quantidadeParcelaEmAberto
                                              }
                                              taxaOrigem={contrato.taxaOrigem}
                                              valorSaldoDevedor={
                                                contrato.valorSaldoDevedor
                                              }
                                              erro={contrato.erro}
                                              listProdutosPortabilidade={
                                                contrato.listProdutosPortabilidade
                                              }
                                              index={index}
                                              parcelasSelected={
                                                parcelasSelected ?? 0
                                              }
                                              propostaId={contrato.propostaId}
                                              simulacao={produto}
                                              id={produto.id}
                                              indexContrato={contratoIndex}
                                            />
                                          )
                                        }
                                      )}
                                    </div>
                                  )
                                }
                                return null
                              }
                            )}
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>
                    </AccordionContainer>
                  )}
                </Col>
              )}
              <Col>
                <Row className="d-flex row-gap-20">{getCardProdutos()}</Row>
              </Col>
            </>
          )}
        </Row>
        <div>
          <SimpleTitleCard className="title-card">
            <div className="title-content">
              <FiPackage className="icon" />
              Produtos Indisponiveis&nbsp;
              <Badge
                color="warning"
                id={`badge-simulacao-${index}`}
                className="custom-badge"
                pill
              />
            </div>
            <Button
              color="link"
              onClick={warningsSimulationToggle}
              className="collapse-btn"
            >
              {warningsSimulation ? (
                <IoIosArrowUp className="icon-alon" />
              ) : (
                <IoIosArrowDown className="icon-alon" />
              )}
            </Button>
          </SimpleTitleCard>
          <Collapse isOpen={warningsSimulation}>
            <Table responsive>
              <thead>
                <tr>
                  <th>Banco</th>
                  <th>Produto</th>
                  <th>Matrícula</th>
                  <th>Descrição</th>
                </tr>
              </thead>
              <tbody id={`notificacao-simulacao-${index}`} />
            </Table>
          </Collapse>
        </div>
        <div>
          <SimpleTitleCard onClick={toggle} className="title-card">
            <div className="title-content">
              <FiPackage className="icon" /> Outros produtos
            </div>
            <Button color="link" onClick={toggle} className="collapse-btn">
              {isOpen ? (
                <IoIosArrowUp className="icon-alon" />
              ) : (
                <IoIosArrowDown className="icon-alon" />
              )}
            </Button>
          </SimpleTitleCard>
          <Collapse isOpen={isOpen}>
            <div className="d-flex justify-content-center justify-content-sm-start flex-wrap gap-4">
              <CardOutrosProdutos
                origem="Produto Riber"
                titulo="Imóvel em garantia"
                descricao="De R$ 30 mil a R$ 3 milhões Juros a partir de 0,85% ao mês + IPCA."
                valorMinimo="30000.00"
                valorMaximo="3000000.00"
              />
              <CardOutrosProdutos
                origem="Produto Riber"
                titulo="Carro em garantia"
                descricao="De R$ 5 mil a R$ 150 mil. Juros a partir de 0,99% ao mês."
                valorMinimo="30000.00"
                valorMaximo="3000000.00"
              />
            </div>
          </Collapse>
        </div>
        <SimpleTitleCard>
          <FiPackage className="icon" />
          Produtos já contratados pelo cliente
        </SimpleTitleCard>
        <div className="d-flex flex-column gap-3">
          {atendimentosAbertos[index].tabProduto?.produtosContratados
            ?.slice()
            .sort((a, b) => {
              const dateA = a.dataContratacao
                ? Date.parse(a.dataContratacao)
                : 0
              const dateB = b.dataContratacao
                ? Date.parse(b.dataContratacao)
                : 0
              return dateB - dateA
            })
            .map((produto, i) => (
              <CardProdutosContratados key={i} produto={produto} />
            ))}
        </div>
      </SimpleCard>
      <ResumoContratacao
        isOpen={atendimentosAbertos[index].tabProduto.modalResumoContratacao}
        onToggle={() => toggleModal()}
        index={index}
      />
      <PropostaIndicacao
        isOpen={atendimentosAbertos[index].tabProduto.modalIndicacao}
        toggle={() => {
          toggleModalIndicacao()
        }}
        cpf={atendimentosAbertos[index].cpf}
        mensagens={atendimentosAbertos[index].tabProduto.mensagemModalIndicacao}
        matriculas={
          atendimentosAbertos[index].tabProduto.matriculasModalIndicacao
        }
      />
      <PropostaIndicacaoManual
        isOpen={atendimentosAbertos[index].tabProduto.modalIndicacaoManual}
        toggle={() => {
          toggleModalIndicacaoManual()
        }}
        cpf={atendimentosAbertos[index].cpf}
      />
      <Agregacao
        index={index}
        onToggle={toggleModalAgregacao}
        isOpen={atendimentosAbertos[index].tabProduto.modalAgregacao}
      />
      <ElegibilidadeRefin
        isOpen={atendimentosAbertos[index].tabProduto.modalInegibilidade}
        toggle={() => {
          toggleModalElegibilidadeRefin()
        }}
        mensagemInegibilidade={
          atendimentosAbertos[index].tabProduto.mensagemInegibilidade
        }
        modalInegibilidade={
          atendimentosAbertos[index].tabProduto.modalInegibilidade
        }
        cpf={atendimentosAbertos[index].cpf}
      />

      <CoberturaSeguroPrestamista
        isOpen={
          atendimentosAbertos[index].tabProduto.modalCoberturasSeguroPrestamista
        }
        toggle={() => {
          toggleModalSeguroPlanoPrestamista()
        }}
        index={index}
      />
      <SeguroSindnapi
        isOpen={atendimentosAbertos[index].tabProduto.modalSeguroSindnapi}
        toggle={() => {
          toggleModalSeguroSindnapi()
        }}
        index={index}
      />
    </TabPane>
  )
}

export default Produto
