import { ErroSimulacao } from 'models/types'
import {
  BancoColumn,
  MatriculaColumn,
  MensagemColumn,
  ProdutoColumn
} from './style'

interface Props {
  message: ErroSimulacao
}

const NotificacaoSimulacao = ({ message }: Props) => {
  return (
    <>
      <BancoColumn>{message.banco}</BancoColumn>
      <ProdutoColumn>{message.produto}</ProdutoColumn>
      <MatriculaColumn>{message.matricula}</MatriculaColumn>
      <MensagemColumn>{message.mensagem}</MensagemColumn>
    </>
  )
}

export default NotificacaoSimulacao
