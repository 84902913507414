import * as yup from 'yup'
import moment from 'moment'

export const schemaTab1 = yup.object().shape({
  id: yup.string().nullable(),
  descricao: yup.string().required(),
  empresasGrupo: yup.array().of(yup.string().required()).min(1),
  tipoOperacaoId: yup.string().nullable().ensure(),
  dataInicioCampanha: yup
    .date()
    .min(moment().format('YYYY-MM-DD'))
    .max(yup.ref('dataFimCampanha'))
    .required(),
  dataFimCampanha: yup.date().min(yup.ref('dataInicioCampanha')).required(),
  ufCampanha: yup.array().of(yup.string().required()),
  quantidadeClientes: yup.number().moreThan(0).required(),
  cidadeCampanha: yup.array().of(yup.string().required())
})

export const schemaTab2 = yup.object().shape(
  {
    redudancia: yup.boolean().required(),
    indisponibilidade: yup.boolean().required(),
    naoPertube: yup.boolean().required(),
    discadorAutomatico: yup.boolean().required(),
    telefone: yup.string().nullable(),
    idadeInicial: yup
      .number()
      .when('idadeFinal', {
        is: (idadeFinal: number) => !!idadeFinal || idadeFinal === 0,
        then: yup.number().min(0).max(yup.ref('idadeFinal'))
      })
      .transform((v) => (v || v === 0 ? v : null))
      .nullable(),
    idadeFinal: yup
      .number()
      .when('idadeInicial', {
        is: (idadeInicial: number) => !!idadeInicial || idadeInicial === 0,
        then: yup.number().min(yup.ref('idadeInicial')).max(150)
      })
      .transform((v) => (v || v === 0 ? v : null))
      .nullable(),
    formaRecebimentoBeneficio: yup.number().nullable(),
    cartaoInss: yup.boolean().nullable(),
    bancosCartaoCampanha: yup.array().of(yup.string().required()),
    despachoAnoInicial: yup
      .date()
      .when('despachoAnoFinal', {
        is: (despachoAnoFinal: number) => !!despachoAnoFinal,
        then: yup.date().max(yup.ref('despachoAnoFinal'))
      })
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    despachoAnoFinal: yup
      .date()
      .when('despachoAnoInicial', {
        is: (despachoAnoInicial: number) => !!despachoAnoInicial,
        then: yup
          .date()
          .min(yup.ref('despachoAnoInicial'))
          .max(moment().add(-1, 'months').endOf('month').format('YYYY-MM-DD'))
      })
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    especies: yup.array().of(yup.number().required()),
    quantidadeEmprestimo: yup.boolean().required(),
    quantidadeInicialEmprestimo: yup
      .number()
      .when('quantidadeEmprestimo', {
        is: true,
        then: yup
          .number()
          .min(1)
          .max(yup.ref('quantidadeFinalEmprestimo'))
          .required()
      })
      .transform((v) => (v || v === 0 ? v : null))
      .nullable(),
    quantidadeFinalEmprestimo: yup
      .number()
      .when('quantidadeEmprestimo', {
        is: true,
        then: yup
          .number()
          .min(yup.ref('quantidadeInicialEmprestimo'))
          .max(9)
          .required()
      })
      .transform((v) => (v || v === 0 ? v : null))
      .nullable(),
    salario: yup.boolean().oneOf([true]).required(),
    quantidadeInicialSalario: yup
      .number()
      .when('salario', {
        is: true,
        then: yup
          .number()
          .min(0)
          .max(yup.ref('quantidadeFinalSalario'))
          .required()
      })
      .nullable(),
    quantidadeFinalSalario: yup
      .number()
      .when('salario', {
        is: true,
        then: yup
          .number()
          .min(yup.ref('quantidadeInicialSalario'))
          .max(1000000000.0)
          .required()
      })
      .nullable(),
    margemLivre: yup.boolean().required(),
    representanteLegal: yup.boolean().nullable(),
    quantidadeInicialMargem: yup
      .number()
      .when('margemLivre', {
        is: true,
        then: yup
          .number()
          .required()
          .min(0)
          .max(yup.ref('quantidadeFinalMargem'))
      })
      .transform((v) => (v || v === 0 ? v : null))
      .nullable(),
    quantidadeFinalMargem: yup
      .number()
      .when('margemLivre', {
        is: true,
        then: yup
          .number()
          .required()
          .min(yup.ref('quantidadeInicialMargem'))
          .max(1000000000.0)
      })
      .transform((v) => (v || v === 0 ? v : null))
      .nullable(),
    margemCartao: yup.boolean().required(),
    quantidadeInicialMargemCartao: yup
      .number()
      .when('margemCartao', {
        is: true,
        then: yup
          .number()
          .required()
          .min(0)
          .max(yup.ref('quantidadeFinalMargemCartao'))
      })
      .transform((v) => (v || v === 0 ? v : null))
      .nullable(),
    quantidadeFinalMargemCartao: yup
      .number()
      .when('margemCartao', {
        is: true,
        then: yup
          .number()
          .required()
          .min(yup.ref('quantidadeInicialMargemCartao'))
          .max(1000000000.0)
      })
      .transform((v) => (v || v === 0 ? v : null))
      .nullable()
  },
  [
    ['idadeInicial', 'idadeFinal'],
    ['despachoAnoInicial', 'despachoAnoFinal']
  ]
)
export const schemaTab3 = yup.object().shape({
  bancosPagadoresConsiderarCampanha: yup.array().of(yup.string().required())
})

export const schemaTab4 = yup.object().shape({
  instituicaoFinanceiraCampanha: yup.array().of(yup.string().required()),
  parcelas: yup.boolean().required(),
  quantidadeInicialParcelas: yup
    .number()
    .when('parcelas', {
      is: true,
      then: yup.number().min(0).max(yup.ref('quantidadeFinalParcelas'))
    })
    .transform((v) => {
      return v || v === 0 ? v : null
    })
    .nullable(),
  quantidadeFinalParcelas: yup
    .number()
    .when('parcelas', {
      is: true,
      then: yup.number().min(yup.ref('quantidadeInicialParcelas'))
    })
    .transform((v) => {
      return v || v === 0 ? v : null
    })
    .nullable(),
  valorParcela: yup.boolean().required(),
  quantidadeInicialValorParcela: yup
    .number()
    .when('valorParcela', {
      is: true,
      then: yup
        .number()
        .required()
        .min(0)
        .max(yup.ref('quantidadeFinalValorParcela'))
    })
    .transform((v) => {
      return v || v === 0 ? v : null
    })
    .nullable(),
  quantidadeFinalValorParcela: yup
    .number()
    .when('valorParcela', {
      is: true,
      then: yup
        .number()
        .required()
        .min(yup.ref('quantidadeInicialValorParcela'))
        .max(1000000000.0)
    })
    .transform((v) => {
      return v || v === 0 ? v : null
    })
    .nullable()
})
