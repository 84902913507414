import { useCallback, useEffect, useState } from 'react'
import CustomTable from 'components/CustomTable'
import { FiGlobe, FiTrash2 } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { SimpleTitleCard } from 'styles/global'
import { COLUMNS_TAB_MATRICULAS } from '../constants/columns'
import BigNumbersConsig from './BigNumbersConsig'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import LoadingBasic from './LoadingBasic'
import { Col, Row } from 'reactstrap'
import ModalConfirmacao from 'components/ModalConfirmacao'
import { pdf } from '@react-pdf/renderer'
import SimpleModal from 'components/SimpleModal'
import { Demonstrativo } from '../components/Pdf/Demonstrativo'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { getDemonstrativos } from 'controller/atendimentoController'
import { removeMaskCPF } from 'util/masks'
import * as loadingActions from 'store/modules/loading/actions'
import { handlerError } from 'util/handlerError'

interface HistoricoMatriculaProps {
  index: number
  indexBeneficio: number
  tipoPerfil: number
}

const HistoricoMatricula = ({
  index,
  indexBeneficio,
  tipoPerfil
}: HistoricoMatriculaProps) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()

  const [modalConfirmacao, setModalConfirmacao] = useState(false)
  const [modalDemonstrativo, setModalDemonstrativo] = useState(false)
  const [pdfUrl, setPdfUrl] = useState<string | null>(null)

  const getContratos = useCallback(() => {
    dispatch(
      atendimentoActions.getContratosSaga(
        atendimentosAbertos[index].cpf,
        index,
        indexBeneficio
      )
    )
  }, [dispatch, atendimentosAbertos, index, indexBeneficio])

  const getInfosClienteConsig = useCallback(() => {
    dispatch(
      atendimentoActions.getInfosClienteConsigSaga(index, indexBeneficio)
    )
  }, [dispatch, index, indexBeneficio])

  function setPage(value: number) {
    dispatch(
      atendimentoActions.setPaginationContratos(
        value,
        'page',
        index,
        indexBeneficio
      )
    )
    getContratos()
  }

  function setPageSize(value: number) {
    dispatch(
      atendimentoActions.setPaginationContratos(
        0,
        'page',
        index,
        indexBeneficio
      )
    )
    dispatch(
      atendimentoActions.setPaginationContratos(
        value,
        'pageSize',
        index,
        indexBeneficio
      )
    )
    getContratos()
  }

  useEffect(() => {
    getContratos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabProduto.beneficios])

  useEffect(() => {
    if (
      !atendimentosAbertos[index].tabProduto.loadingSimulacao ||
      !atendimentosAbertos[index].tabProduto.beneficios?.[indexBeneficio]
        ?.loading
    ) {
      getInfosClienteConsig()
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    atendimentosAbertos[index].tabProduto.loadingSimulacao,
    atendimentosAbertos[index].tabProduto.beneficios?.[indexBeneficio]?.loading,
    index,
    indexBeneficio
  ])
  /* eslint-enable react-hooks/exhaustive-deps */

  const generatePDF = async () => {
    try {
      dispatch(loadingActions.setLoading(true))
      const cpfFormated = removeMaskCPF(atendimentosAbertos[index].cpf)
      const matricula =
        atendimentosAbertos[index].tabProduto.beneficios?.[indexBeneficio]
          ?.numero
      const response = await getDemonstrativos(cpfFormated, matricula)
      const doc = <Demonstrativo demonstrativo={response.data.content} />
      const blob = await pdf(doc).toBlob()
      if (blob) {
        const url = URL.createObjectURL(blob)
        setPdfUrl(url)
        setModalDemonstrativo(true)
      }
    } catch (error) {
      handlerError(error)
    } finally {
      dispatch(loadingActions.setLoading(false))
    }
  }

  return (
    <>
      {atendimentosAbertos[index].tabProduto.loadingSimulacao ? (
        <LoadingBasic
          mainText="Estamos realizando a atualização dos dados do cliente"
          secondaryText="Aguarde..."
        />
      ) : (
        <BigNumbersConsig
          especie={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.especie
          }
          idade={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.idade
          }
          margemBeneficio={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.margemCartaoBeneficio
          }
          margemConsignado={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.margemCartaoConsignado
          }
          margemEmprestimo={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.margemDisponivel
          }
          margemComplementar={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.margemComplementar
          }
          nome={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.nome || ''
          }
          salario={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.valorRenda
          }
          situacao={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.situacao
          }
          beneficioPermiteEmprestimo={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.beneficioPermiteEmprestimo
          }
          bloqueioEmprestismo={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.bloqueioEmprestismo
          }
          possuiRepresentanteLegalProcurador={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.possuiRepresentanteLegalProcurador
          }
          pensaoAlimenticia={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.pensaoAlimenticia
          }
          dataDespachoBeneficio={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.dataDespachoBeneficio
          }
          convenio={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas[
              indexBeneficio
            ].infosConsig?.convenio
          }
          tipoPerfil={tipoPerfil}
        />
      )}
      <Row className="row-top-30">
        <Col className="d-flex justify-content-start align-items-center gap-2 my-2">
          {Number(
            atendimentosAbertos[index].tabProduto.beneficios?.[indexBeneficio]
              ?.numero
          ) !== 0 &&
            atendimentosAbertos[index].tabProduto.beneficios?.[indexBeneficio]
              ?.numero && (
              <div
                className="pdf-button d-flex align-items-center"
                onClick={generatePDF}
              >
                <AiOutlineFilePdf />
                Gerar PDF
              </div>
            )}
        </Col>
        <Col className="d-flex justify-content-end align-items-center gap-2 my-2">
          <div
            onClick={() => setModalConfirmacao(true)}
            className="btn-outline-purple"
          >
            <FiTrash2 className="icon" />
            Remover matrícula
          </div>
        </Col>
      </Row>

      <ModalConfirmacao
        isOpen={modalConfirmacao}
        toggle={() => setModalConfirmacao(false)}
        onConfirm={() => {
          dispatch(
            atendimentoActions.removerMatriculaSaga(index, indexBeneficio)
          )
        }}
      >
        Tem certeza que deseja remover a matrícula?
      </ModalConfirmacao>
      <SimpleModal
        isOpen={modalDemonstrativo}
        toggle={() => setModalDemonstrativo(false)}
        size="xl"
        header="Vizualização do PDF"
      >
        <div
          style={{
            height: '80vh'
          }}
        >
          <iframe
            src={pdfUrl ?? ''}
            width="100%"
            height="100%"
            style={{ border: '1px solid #ccc' }}
          />
        </div>
      </SimpleModal>
      <div className="linha-horizontal mb-3" />
      <SimpleTitleCard>
        <FiGlobe className="icon" /> Contratos
      </SimpleTitleCard>
      <div className="container-fluid d-flex flex-column row-gap-10">
        <CustomTable
          data={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas?.[
              indexBeneficio
            ]?.contratos
          }
          columns={COLUMNS_TAB_MATRICULAS}
          page={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas?.[
              indexBeneficio
            ]?.tabelaContratos.page
          }
          pageSize={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas?.[
              indexBeneficio
            ]?.tabelaContratos.pageSize
          }
          rowCount={
            atendimentosAbertos[index].tabMatriculas.infosMatriculas?.[
              indexBeneficio
            ]?.tabelaContratos.totalRegistros || 0
          }
          setPage={(e) => setPage(e)}
          setPageSize={(e) => setPageSize(e)}
          getRowHeight={() => 'auto'}
        />
      </div>
    </>
  )
}

export default HistoricoMatricula
