import { Font, StyleSheet } from '@react-pdf/renderer'
Font.register({
  family: 'Times New Roman',
  fonts: [
    {
      src: require('../../../../assets/fonts/TimesNewRoman.ttf'),
      fontWeight: 'normal'
    },
    {
      src: require('../../../../assets/fonts/TimesNewRomanBold.ttf'),
      fontWeight: 'bold'
    },
    {
      src: require('../../../../assets/fonts/NimbusRomNo9L-Medi.ttf'),
      fontWeight: 'medium'
    }
  ]
})

Font.register({
  family: 'Arial',
  fonts: [
    {
      src: require('../../../../assets/fonts/Arial.ttf'),
      fontWeight: 'normal'
    },
    {
      src: require('../../../../assets/fonts/ArialBold.ttf'),
      fontWeight: 'bold'
    },
    {
      src: require('../../../../assets/fonts/ArialMedium.ttf'),
      fontWeight: 'medium'
    }
  ]
})

export const styles = StyleSheet.create({
  page: {
    padding: 5,
    fontFamily: 'Times New Roman',
    fontSize: 7
  },
  title: {
    fontSize: 8,
    textAlign: 'center',
    marginBottom: 30,
    fontWeight: 'bold'
  },
  headerDate: {
    textAlign: 'right',
    marginBottom: 15,
    fontSize: 7
  },
  section: {
    border: '0.5px solid #666',
    borderRadius: 4,
    padding: 6,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  sectionTitleContainer: {
    position: 'absolute',
    top: -5,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'white',
    paddingHorizontal: 1
  },
  sectionTitle: {
    fontFamily: 'Arial',
    fontWeight: 'medium',
    fontSize: 8,
    color: '#363636'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontFamily: 'Times New Roman',
    gap: 18,
    color: '#363636'
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Times New Roman',
    textAlign: 'center',
    marginTop: 8,
    gap: 2,
    flex: 1,
    color: '#363636'
  },
  tableRow: {
    width: '100%',
    flexDirection: 'row',
    minHeight: 15
  },
  cellText: {
    textAlign: 'center',
    fontFamily: 'Times New Roman',
    color: '#363636',
    fontSize: 7,
    width: '100%'
  },
  cell: {
    flex: 1,
    padding: 1,
    textAlign: 'center',
    fontFamily: 'Times New Roman',
    marginTop: 5,
    color: '#363636'
  },
  tableHeader: {
    fontFamily: 'Arial',
    fontWeight: 'medium',
    color: '#363636',
    borderRadius: 8,
    marginBottom: 1,
    padding: 2
  },
  footer: {
    marginTop: 1,
    padding: 1,
    textAlign: 'center',
    border: '1px solid #363636',
    color: '#363636',
    borderRadius: 2,
    fontSize: 8,
    fontFamily: 'Arial',
    fontWeight: 'medium'
  }
})
