import NotificacaoSimulacao from 'components/NotificacaoSimulacao'
import { ErroSimulacao } from 'models/types'
import { createRoot } from 'react-dom/client'

function updateBadge(index: number, count: number) {
  const badge = document.getElementById(`badge-simulacao-${index}`)
  if (badge) {
    badge.textContent = `${count}`
  }
}

function renderNotification(container: HTMLElement, message: ErroSimulacao) {
  const row = document.createElement('tr')
  container.appendChild(row)

  const root = createRoot(row)
  root.render(<NotificacaoSimulacao message={message} />)
}

export function handlerErrorSimulationService(
  error: any,
  index: number,
  defaultMessage?: string
) {
  const errors: ErroSimulacao[] = []
  if (
    (error?.message ||
      (error?.messages?.length > 0 && error?.messages) ||
      (error?.mensagensErro?.length > 0 && error?.mensagensErro)) &&
    !error?.stack
  ) {
    if (error?.mensagensErro?.length > 0) {
      errors.push(...error.mensagensErro)
    } else if (error?.messages?.length > 0) {
      errors.push(
        ...error.messages.map((erro: string) => {
          return {
            matricula: '-',
            produto: '-',
            banco: '-',
            mensagem: erro
          }
        })
      )
    } else {
      errors.push({
        matricula: '-',
        produto: '-',
        banco: '-',
        mensagem: error.message
      })
    }
  } else {
    errors.push({
      matricula: '-',
      produto: '-',
      banco: '-',
      mensagem: defaultMessage || 'Não foi possível!'
    })
  }

  const container = document.getElementById(`notificacao-simulacao-${index}`)
  if (!container) {
    console.warn('Notification container not found')
    return
  }

  const existingNotificationsCount = container.children.length

  errors.forEach((message) => {
    renderNotification(container, message)
  })

  const totalNotificationsCount = existingNotificationsCount + errors.length
  updateBadge(index, totalNotificationsCount)
}

export function clearNotifications(index: number) {
  const container = document.getElementById(`notificacao-simulacao-${index}`)
  if (container) {
    container.innerHTML = ''
    updateBadge(index, 0)
  }
}
