import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from './style'
import moment from 'moment'
import { Demonstrativo as DemonstrativoType } from 'models/atendimento/types'

type MeuPDFProps = {
  demonstrativo?: DemonstrativoType
}

const Section: React.FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children
}) => (
  <View style={styles.section}>
    <View style={styles.sectionTitleContainer}>
      <Text style={styles.sectionTitle}>{title}</Text>
    </View>
    {children}
  </View>
)

export const Demonstrativo: React.FC<MeuPDFProps> = ({ demonstrativo }) => {
  if (!demonstrativo) {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text style={styles.title}>Nenhum demonstrativo encontrado.</Text>
        </Page>
      </Document>
    )
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={[styles.title, { fontWeight: 'normal' }]}>
          Demonstrativo de Empréstimos Consignados
        </Text>
        <Text style={styles.headerDate}>
          {moment().format('DD/MM/YYYY HH:mm')}
        </Text>

        <Section title="Identificação do Filiado">
          <View style={styles.row}>
            <Text>
              <Text
                style={{
                  fontWeight: 'medium',
                  marginTop: 8
                }}
              >
                Nome:
              </Text>{' '}
              {demonstrativo.nome}
            </Text>
            <Text>
              <Text style={{ fontWeight: 'medium', marginTop: 8 }}>NB:</Text>{' '}
              {demonstrativo.informacoesBeneficio.numeroMatricula}
            </Text>
            <Text>
              <Text style={{ fontWeight: 'medium', marginTop: 8 }}>CPF:</Text>{' '}
              {demonstrativo.cpf}
            </Text>
          </View>
        </Section>

        <Section title="Dados do Benefício">
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: 5
            }}
          >
            <Text>
              <Text style={{ fontWeight: 'medium' }}>Espécie: </Text>
              {demonstrativo.informacoesBeneficio.especie}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: 5
            }}
          >
            <Text>
              <Text style={{ fontWeight: 'medium' }}>Situação: </Text>
              {demonstrativo.informacoesBeneficio.situacao}
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              marginBottom: 5
            }}
          >
            <Text
              style={{
                fontSize: 8,
                fontWeight: 'medium',
                alignItems: 'flex-start',
                textDecoration: 'underline',
                marginRight: 299
              }}
            >
              Características:
            </Text>
          </View>

          <View style={{ flexDirection: 'row', marginBottom: 5 }}>
            <Text style={{ marginRight: 20 }}>
              <Text style={{ fontWeight: 'medium' }}>
                É Pensão Alimentícia:{' '}
              </Text>
              {demonstrativo.informacoesBeneficio.pensaoAlimenticia
                ? 'Sim'
                : 'Não'}
            </Text>
            <Text style={{ marginRight: 20 }}>
              <Text style={{ fontWeight: 'medium' }}>
                Possui Representante Legal:{' '}
              </Text>
              {demonstrativo.informacoesBeneficio.representanteLegal
                ? 'Sim'
                : 'Não'}
            </Text>
            <Text style={{ marginRight: 20 }}>
              <Text style={{ fontWeight: 'medium' }}>
                Bloqueado para Empréstimo:{' '}
              </Text>
              {demonstrativo.informacoesBeneficio.bloqueioEmprestimo
                ? 'Sim'
                : 'Não'}
            </Text>
            <Text>
              <Text style={{ fontWeight: 'medium' }}>
                Elegível para Empréstimo:{' '}
              </Text>
              {demonstrativo.informacoesBeneficio.elegivelEmprestimo
                ? 'Sim'
                : 'Não'}
            </Text>
          </View>
        </Section>

        <Section title="Margem Consignável">
          <View style={styles.row}>
            <Text>
              <Text style={{ fontWeight: 'medium' }}>Base de Cálculo:</Text> R${' '}
              {demonstrativo.informacoesBeneficio.salario}
            </Text>
            <Text>
              <Text style={{ fontWeight: 'medium' }}>
                Margem para Empréstimo:
              </Text>{' '}
              R$ {demonstrativo.informacoesBeneficio.margemEmprestimo}
            </Text>
            <Text>
              <Text style={{ fontWeight: 'medium' }}>Margem RMC:</Text> R${' '}
              {demonstrativo.informacoesBeneficio.margemRmc}
            </Text>
            <Text>
              <Text style={{ fontWeight: 'medium' }}>Margem RCC:</Text> R${' '}
              {demonstrativo.informacoesBeneficio.margemRcc}
            </Text>
          </View>
        </Section>

        <Section title="Instituição Pagadora">
          <View style={styles.row}>
            <Text>
              <Text style={{ fontWeight: 'medium' }}>CBC/Banco:</Text>{' '}
              {demonstrativo.instituicaoPagadora.codigoBanco}
            </Text>
            <Text>
              <Text style={{ fontWeight: 'medium' }}>Tipo:</Text>{' '}
              {demonstrativo.instituicaoPagadora.tipo}
            </Text>
            <Text>
              <Text style={{ fontWeight: 'medium' }}>Ag.:</Text>{' '}
              {demonstrativo.instituicaoPagadora.agencia}
            </Text>
            <Text>
              <Text style={{ fontWeight: 'medium' }}>C/C.:</Text>{' '}
              {demonstrativo.instituicaoPagadora.contaCorrente}
            </Text>
          </View>
        </Section>

        <Section title="Contratos de Empréstimos">
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={styles.cell}>Empréstimo</Text>
            <Text style={styles.cell}>CBC/Banco</Text>
            <Text style={styles.cell}>Comp. 1º Parcela</Text>
            <Text style={styles.cell}>Comp. Última Parcela</Text>
            <Text style={styles.cell}>Data Averbação</Text>
            <Text style={styles.cell}>Parcelas</Text>
            <Text style={styles.cell}>Valor Parcela</Text>
            <Text style={styles.cell}>Valor Emprestado</Text>
          </View>

          {demonstrativo.informacoesContratosEmprestimo.map(
            (contrato, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={[styles.column]}>
                  <Text style={styles.cell}>{contrato.numeroContrato} </Text>
                  {'\n'}
                  <Text style={styles.cell}>
                    <Text style={{ fontWeight: 'medium' }}>Situação: </Text>{' '}
                    {contrato.situacao}
                  </Text>
                </Text>
                <Text style={styles.cell}>{contrato.banco}</Text>
                <Text style={styles.cell}>
                  {contrato.competenciaInicioDesconto}
                </Text>
                <Text style={styles.cell}>
                  {contrato.competenciaFimDesconto}
                </Text>
                <Text style={styles.cell}>{contrato.dataAverbacao}</Text>
                <Text style={styles.cell}>{contrato.parcelas}</Text>
                <Text style={styles.cell}>R$ {contrato.valorParcela}</Text>
                <Text style={styles.cell}>R$ {contrato.valorTotal}</Text>
              </View>
            )
          )}
        </Section>
        <Section title="Contratos Cartão">
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={styles.cell}>Nº Contrato</Text>
            <Text style={styles.cell}>CBC/Banco</Text>
            <Text style={styles.cell}>Data inclusão</Text>
            <Text style={styles.cell}>Situação</Text>
            <Text style={styles.cell}>Limite</Text>
            <Text style={styles.cell}>Valor</Text>
          </View>

          {demonstrativo.informacoesContratosCartao.map((contrato, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.cell}>{contrato.numeroContrato}</Text>
              <Text style={styles.cell}>{contrato.banco}</Text>
              <Text style={styles.cell}>{contrato.dataInclusao}</Text>
              <Text style={styles.cell}>{contrato.situacao}</Text>
              <Text style={styles.cell}>R$ {contrato.limite}</Text>
              <Text style={styles.cell}>R$ {contrato.valorTotal}</Text>
            </View>
          ))}
        </Section>

        <Section title="Contratos RCC">
          <View style={[styles.tableRow, styles.tableHeader]}>
            <Text style={styles.cell}>Nº Contrato</Text>
            <Text style={styles.cell}>CBC/Banco</Text>
            <Text style={styles.cell}>Data inclusão</Text>
            <Text style={styles.cell}>Situação</Text>
            <Text style={styles.cell}>Limite</Text>
            <Text style={styles.cell}>Valor</Text>
          </View>

          {demonstrativo.informacoesContratosRcc.map((contrato, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.cell}>{contrato.numeroContrato}</Text>
              <Text style={styles.cell}>{contrato.banco}</Text>
              <Text style={styles.cell}>{contrato.dataInclusao}</Text>
              <Text style={styles.cell}>{contrato.situacao}</Text>
              <Text style={styles.cell}>R$ {contrato.limite}</Text>
              <Text style={styles.cell}>R$ {contrato.valorTotal}</Text>
            </View>
          ))}
        </Section>

        <Text style={styles.footer}>
          Demonstrativo para simples conferência - Apenas empréstimos ativos e
          suspensos
        </Text>
      </Page>
    </Document>
  )
}
