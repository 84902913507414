import React, { useEffect, useState } from 'react'
import { FiGlobe } from 'react-icons/fi'
import { TabPane } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import SimpleCard from 'components/SimpleCard'
import SimpleTab from 'components/SimpleTab/SimpleTab'
import HistoricoMatricula from '../HistoricoMatricula'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { EscolhaPerfil } from '../Modals/EscolhaPerfil'
import ModalMatricula from '../Modals/ModalMatricula'

/**
 * Matriculas
 * TabId = 4
 * **/

interface Props {
  index: number
}

const Matriculas = ({ index }: Props) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const { dispatch } = useRedux()
  const [openModalBeneficio, setOpenModalBeneficio] = useState(false)
  const [matriculas, setMatriculas] = useState<
    {
      id: number
      descricao: string
      component: JSX.Element
    }[]
  >([])

  useEffect(() => {
    dispatch(atendimentoActions.setTabsMatriculas(index))
    cleanMatriculas()
    const componentsMatriculas: typeof matriculas = []
    atendimentosAbertos[index].tabProduto.beneficios?.map((matricula, i) => {
      componentsMatriculas.push({
        id: i,
        descricao:
          matricula.numero && matricula.numero !== '0'
            ? `${
                matricula.clientePerfil.descricao || 'Descrição indisponível'
              }: ${matricula.numero}`
            : matricula.clientePerfil.descricao || 'Descrição indisponível',
        component: (
          <HistoricoMatricula
            indexBeneficio={i}
            index={index}
            tipoPerfil={matricula.clientePerfil.tipoPerfil}
          />
        )
      })
    })
    setMatriculas(componentsMatriculas)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabProduto.beneficios, dispatch])

  async function cleanMatriculas() {
    setMatriculas([])
  }

  return (
    <TabPane tabId={4}>
      <SimpleCard>
        <div className="d-flex justify-content-between align-items-center">
          <SimpleTitleCard>
            <FiGlobe className="icon" /> Matrículas
          </SimpleTitleCard>
          <div
            onClick={() => {
              setOpenModalBeneficio(true)
            }}
            className="btn-outline-purple"
          >
            <AiOutlinePlusCircle /> Adicionar Matrícula
          </div>
        </div>
        <SimpleTab tabs={matriculas} />
        <EscolhaPerfil
          isOpen={openModalBeneficio}
          onToggle={() => {
            setOpenModalBeneficio(!openModalBeneficio)
            dispatch(atendimentoActions.cleanRegisterMatriculaInvalid(index))
          }}
          onAdicionar={(e) => {
            dispatch(atendimentoActions.adicionarPerfil(e, index))
            dispatch(
              atendimentoActions.setInfosModalMatriculaFields(
                true,
                'isOpen',
                index
              )
            )
          }}
        />
        <ModalMatricula
          isOpen={
            atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
              .isOpen
          }
          onToggle={() => {
            dispatch(
              atendimentoActions.setInfosModalMatriculaFields(
                !atendimentosAbertos[index].tabMatriculas.modalRegisterMatricula
                  .isOpen,
                'isOpen',
                index
              )
            )
            dispatch(atendimentoActions.cleanRegisterMatriculaInvalid(index))
          }}
          index={index}
        />
      </SimpleCard>
    </TabPane>
  )
}

export default Matriculas
