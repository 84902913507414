import styled, { keyframes } from 'styled-components'
import { createGlobalStyle } from 'styled-components'

export const goRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

export const goLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

interface SimpleTitleCardProps {
  color?: string
  colorIcon?: string
}

export const SimpleTitleCard = styled.div<SimpleTitleCardProps>`
  align-items: center;
  color: ${(props) => (props.color ? `#${props.color}` : '#474A57')};
  display: flex;
  flex-direction: row;
  font-family: 'Open Sans', serif;
  font-size: 18px !important;
  font-weight: 700;
  gap: 10px;
  justify-content: left;
  padding-bottom: 20px;

  & .icon {
    color: ${(props) => (props.colorIcon ? `#${props.colorIcon}` : '#00ab4f')};
    width: 25px;
    height: 25px;
  }

  & .icon-alon {
    color: #000000;
    width: 20px;
    height: 20px;
    font-weight: 800;
  }
  .title-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .title-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .collapse-btn {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
`

interface ButtonProps {
  width?: number | string
  minWidth?: number | string
  background?: string
  borderRadius?: number
  border?: string
  letterSpacing?: number
  justifyContent?: string
  color?: string
  isDisabled?: boolean
  padding?: string
  height?: string
  fontWeight?: number
}

export const CustomButton = styled.div<ButtonProps>`
  align-items: center;
  background: ${(props) =>
    props.background
      ? props.background
      : 'linear-gradient(264.61deg, #87df41 -9.34%, #00ab4f 101.57%)'};
  border-radius: ${(props) =>
    props.borderRadius ? `${props.borderRadius}px` : '15px'};
  border: ${(props) => (props.border ? props.border : 'none')};
  color: ${(props) => (props.color ? props.color : '#fff')};
  display: flex;
  padding: ${(props) => (props.padding ? `${props.padding}px` : '10px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 700)};
  font-size: 14px;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};
  letter-spacing: ${(props) =>
    props.letterSpacing ? `${props.letterSpacing}px` : '0px'};
  line-height: 18px;
  height: ${(props) => (props.height ? `${props.height}` : '40px')};
  width: ${(props) => (props.width ? `${props.width}` : '180px')};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}` : 'initial')};

  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
`

export const InfosInputs = styled.div`
  color: #8e8e8e;
  font-size: 10px;
  line-height: 16px;
`

export const Range = styled.input.attrs({ type: 'range' })`
  appearance: none;
  margin: 10px 0;
  width: 100%;
  height: 7px;
  border-radius: 5px;
  background: #cfcfcf linear-gradient(to left, #87df41, #00ab4f) no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  &::-moz-range-thumb {
    appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  &::-ms-thumb {
    appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-moz-range-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-ms-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }

  &::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
  }

  &::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
  }

  &::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
  }

  &:focus::-ms-fill-lower {
    background: #3071a9;
  }

  &:focus::-ms-fill-upper {
    background: #367ebd;
  }
`

const GlobalStyle = createGlobalStyle`

:root {
  --amarelo-riber: #F7931E;
  --vermelho-riber: #F15A24;
  --laranja-riber: #F7931E;
  --azul-riber: #29ABE2;
  --marinho-riber: #0071BC;
  --roxo-riber: #662D91;
  --verde-riber: #00AB4F;
  --preto-riber: #353535;
  --cinza-riber: #8E8E8E;
  --cinzaClaro-riber: #CFCFCF;
  --verdeGradiente-riber: linear-gradient(264.61deg, #87DF41 -9.34%, #00AB4F 101.57%);
  --laranjaGradiente-riber: linear-gradient(264.61deg, #F7931E -9.34%, #F15A24 101.57%);
  --violetaGradiente-riber: linear-gradient(43.84deg, #662D91 -5.32%, #A64DFF 97.27%);
  --azulGradiente-riber: linear-gradient(216.79deg, #13C2C2 3.97%, #4285F4 102.27%);
  --branco: #FFFFFF;
  --preto: #000000;
  --light-green: #B7D9C7;
  --light-orange: #E8D4BD;
  --light-gray: #F9F9F9;
  --dark-gray: #474A57;
}

  *{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
 }

 html, body {
  background-color: #f9f9f9;
  height: 100%;
  font-family: Open Sans;
 }

 .navbar{
  padding: 6px 10vw;
  min-height: 50px;
  position: fixed;
  top: 0;
  z-index: 100;
  background: #fff;
  width: 100%;
  flex-wrap: nowrap;
 }


 .pointer{
   cursor: pointer;
 }

 .label-12 {
  color: #000;
  font-family: Open Sans;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
 }

 .row-gap-10 {
  row-gap: 10px;
 }

 .row-gap-15 {
  row-gap: 15px;
 }

 .row-gap-20 {
  row-gap: 20px;
 }

 .column-gap-10 {
  column-gap: 10px;
 }

 .column-gap-15 {
  column-gap: 15px;
 }

 .column-gap-20 {
  column-gap: 20px;
 }
 .row-top-30 {
  margin-top: 30px;
 }
.btn-outline-white,
.btn-outline-purple,
.btn-white,
.btn-purple  {
   background-color: transparent;
   cursor: pointer;
   min-width: 180px;
   height: 30px;
   border-radius: 5px;
   padding: 10px;
   align-items: center;
   justify-content: space-between;
   display: flex;
   gap: 5px;

   &:disabled {
    cursor: not-allowed;
   }
 }

.btn-outline-purple {
   color: #662d91;
   border: #662d91 solid 1px;
 }

.btn-outline-white {
   border: #fff solid 1px;
 }
.btn-outline-orange {
   color: #f7931e !important;
   border: #f7931e solid 1px;
 }

.btn-purple {
   color: #fff;
   border: #662d91 solid 1px;
   background: #662d91;
 }

.btn-white {
  color: #662d91;
  border: #662d91 solid 1px;
  background: #fff;
 }
.button {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
    width: auto;
  }

  .save-button {
    border: 1px solid var(--roxo-riber);
    color: var(--roxo-riber);
  }

  .clear-button {
    border: 1px solid var(--laranja-riber);
    color: var(--laranja-riber);
    margin-left: 10px; 
  }
  .pdf-button {
    font-size: 16px;
    padding: 5px;
    width:  150px;
    border: 1px solid var(--roxo-riber);
    border-radius: 5px;
    color: var(--roxo-riber);
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
    height: 30px;
      & .icon {
      font-size: 1.1rem;
      color: var(--roxo-riber);
      margin-right: 5px;
    }
}



 .linha-horizontal{
  width: 100%;
  border-bottom: 1px solid #CFCFCF;
 }

 .w-fit {
  width: fit-content;
 }

 .onlyMobile{
   display: none !important;
 }

 .table-header--purple{
  color: #FFFFFF;
  background: #662D91;
 }

 .table-header--lines{
  color: #FFFFFF;
  font-size: 12px;
  & .MuiDataGrid-columnHeaderTitle {
    white-space: break-spaces !important;
    line-height: 14px !important;
  }
 }

 .table-cell--lines {
  font-size: 14px;
  white-space: normal !important;
  word-wrap: break-word !important;
}

 & .selecao-multipla {

  & ::-webkit-scrollbar {
    width: 10px;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 15px;
  }

  & ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  & ::-webkit-scrollbar-track {
    margin: 10px 10px;
  }

  & .remetente, .receptor {
    background: #ffffff;
    border: 1px solid #CFCFCF;
    box-sizing: border-box;
    border-radius: 15px;
    height: 262px;
    margin-top: 10px;
    overflow: auto;
    padding: 10px;
    min-width: 190px;
    width: 100%;
  }

  & .remetente.disabled, .receptor.disabled {
    background: #e9ecef;
  }

  & .remetente.invalid, .receptor.invalid {
    border: 1px solid #dc3545;
  }

  & :is(.remetente, .receptor) ul {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    & :is(.remetente, .receptor) ul>li {
      align-items: center;
      background: #F5F5F5;
      border: 1px solid #F0F0F0;
      box-sizing: border-box;
      border-radius: 5px;
      cursor: pointer;
      padding: 2px 4px 2px 8px;
      white-space: nowrap;
      width: min-content;
    }

    & :is(.remetente.disabled, .receptor.disabled) ul>li {
      cursor: default;
    }

    & :is(.remetente, .receptor) ul>li:hover {
      background: #e5e5e5;
    }

  & :is(.remetente, .receptor) ul>li.active {
    background: #b9b9b9;
    color: #fff;
    border: solid 1px #919191;
  }

  & .btns-selecao {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    justify-content: center;
    margin: 0 25px;
    padding-top: 66px;

    & div{
      align-items: center;
      background: #FFFFFF;
      border: 1px solid #CFCFCF;
      box-sizing: border-box;
      border-radius: 6px;
      color: #8E8E8E;
      cursor: pointer;
      display: flex;
      font-size: 25px;
      justify-content: center;
      margin: 5px 0;
      padding: 10px;

      &:hover{
        background: #e8e8e8;
      }
    }
  }

  & .btns-selecao .disabled {
    background: #e8e8e8;
    cursor: default;
  }
 }

  & .toggler {
    font-size: 30px;
  }

  .table-cell-trucate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .modal-xxl {
    width: 95%;
    min-width: 95%;
  }

  .custom-badge {
    background-color: var(--roxo-riber) !important;
    color: var(--branco);
  }

 @media (max-width: 992px) {
  .onlyMobile{
    display: block !important;
  }
 }

 @media (max-width: 767px) {
  & .selecao-multipla {
    flex-direction: column;

    & .btns-selecao {
      column-gap: 20px;
      flex-direction: row;
      padding-top: 0;

      & div{
        transform: rotate(90deg);
      }
    }
  }
 }
`

export const AtualizacaoTokenWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  & .input-group {
    width: 120px;

    & input {
      height: 35px;
      font-size: 12px;
    }

    & .input-group-text {
      display: flex;
      justify-content: center;
      height: 35px;
      width: 20px;
      padding: 2px;
    }
  }

  & .btn-envio {
    color: #00ab4f;
  }
`

export const drawerWidth = 240

export default GlobalStyle
