import React, { useEffect } from 'react'
import Localizacao from '../Components/Localizacao'
import Campanha from '../Components/Campanha'
import Parametros from '../Components/Parametros'
import RefinanciamentoPortabilidade from '../Components/RefinanciamentoPortabilidade'
import BancosPagadores from '../Components/BancosPagadores'
import MaisInformacoes from '../Components/MaisInformacoes'
import SimpleCard from 'components/SimpleCard'
import classNames from 'classnames'
import useRedux from 'hooks/useRedux'
import * as campanhaActions from 'store/modules/backoffice/campanha/actions'
import * as yup from 'yup'
import { Tab, Ul } from 'components/SimpleTab/style'
import { Col, Row, TabContent, TabPane } from 'reactstrap'
import { CustomButton } from 'styles/global'
import { ApplicationState } from 'store'
import { CAMPANHA } from 'store/modules/backoffice/campanha/types'
import { useSelector } from 'react-redux'
import {
  schemaTab1,
  schemaTab2,
  schemaTab3,
  schemaTab4
} from '../constants/schemas'
import { toast } from 'react-toastify'
import { removeMaskMoney } from 'util/masks'

const Register = () => {
  const { activeTabRegister, register, visualizacao } = useSelector<
    ApplicationState,
    CAMPANHA
  >((state) => state.campanha)

  const { dispatch } = useRedux()

  useEffect(() => {
    dispatch(campanhaActions.getBancosPagadoresSaga())
    dispatch(campanhaActions.getGruposLojasSaga())
    dispatch(campanhaActions.getBancosCartaoSaga())
  }, [dispatch])

  function cleanAllInvalids() {
    dispatch(campanhaActions.cleanInvalidsTabDadosGerais())
    dispatch(campanhaActions.cleanInvalidsTabParametros())
    dispatch(campanhaActions.cleanInvalidsTabBancos())
    dispatch(
      campanhaActions.cleanInvalidsTabRefinsetInvalidsTabRefinPortabilidade()
    )
  }

  function onSave() {
    cleanAllInvalids()

    schemaTab1
      .validate(
        {
          id: register.id,
          descricao: register.descricao,
          empresasGrupo: register.empresasGrupo,
          tipoOperacaoId: register.tipoOperacaoId,
          dataInicioCampanha: register.dataInicioCampanha,
          dataFimCampanha: register.dataFimCampanha,
          quantidadeClientes: register.quantidadeClientes,
          ufCampanha: register.ufCampanha,
          cidadeCampanha: register.cidadeCampanha
        },
        { abortEarly: false }
      )
      .then(() => {
        const quantidadeInicialSalario = register.quantidadeInicialSalario
          ? Number(removeMaskMoney(register.quantidadeInicialSalario))
          : null
        const quantidadeFinalSalario = register.quantidadeFinalSalario
          ? Number(removeMaskMoney(register.quantidadeFinalSalario))
          : null
        schemaTab2
          .validate(
            {
              redudancia: register.redudancia,
              indisponibilidade: register.indisponibilidade,
              naoPertube: register.naoPertube,
              discadorAutomatico: register.discadorAutomatico,
              telefone: register.telefone,
              idadeInicial: register.idadeInicial,
              idadeFinal: register.idadeFinal,
              formaRecebimentoBeneficio: register.formaRecebimentoBeneficio,
              cartaoInss: register.cartaoInss,
              bancosCartaoCampanha: register.bancosCartaoCampanha,
              despachoAnoInicial: register.despachoAnoInicial ?? null,
              despachoAnoFinal: register.despachoAnoFinal ?? null,
              especies: register.especies,
              quantidadeEmprestimo: register.quantidadeEmprestimo,
              quantidadeInicialEmprestimo: register.quantidadeInicialEmprestimo,
              quantidadeFinalEmprestimo: register.quantidadeFinalEmprestimo,
              salario: register.salario,
              quantidadeInicialSalario: quantidadeInicialSalario,
              quantidadeFinalSalario: quantidadeFinalSalario,
              margemLivre: register.margemLivre,
              quantidadeInicialMargem: Number(
                removeMaskMoney(register.quantidadeInicialMargem)
              ),
              quantidadeFinalMargem: Number(
                removeMaskMoney(register.quantidadeFinalMargem)
              ),
              margemCartao: register.margemCartao,
              quantidadeInicialMargemCartao: Number(
                removeMaskMoney(register.quantidadeInicialMargemCartao)
              ),
              quantidadeFinalMargemCartao: Number(
                removeMaskMoney(register.quantidadeFinalMargemCartao)
              ),
              representanteLegal: register.representanteLegal
            },
            { abortEarly: false }
          )
          .then(() => {
            schemaTab3
              .validate(
                {
                  bancosPagadoresConsiderarCampanha:
                    register.bancosPagadoresConsiderarCampanha
                },
                { abortEarly: false }
              )
              .then(() => {
                schemaTab4
                  .validate(
                    {
                      instituicaoFinanceiraCampanha:
                        register.instituicaoFinanceiraCampanha,
                      parcelas: register.parcelas,
                      quantidadeInicialParcelas:
                        register.quantidadeInicialParcelas,
                      quantidadeFinalParcelas: register.quantidadeFinalParcelas,
                      valorParcela: register.valorParcela,
                      quantidadeInicialValorParcela: Number(
                        removeMaskMoney(register.quantidadeInicialValorParcela)
                      ),
                      quantidadeFinalValorParcela: Number(
                        removeMaskMoney(register.quantidadeFinalValorParcela)
                      )
                    },
                    { abortEarly: false }
                  )
                  .then(() => {
                    if (register.id) {
                      dispatch(campanhaActions.atualizarCampanhaSaga())
                    } else {
                      dispatch(campanhaActions.criarCampanhaSaga())
                    }
                  })
                  .catch((err) => {
                    err.inner.forEach((e: yup.ValidationError) => {
                      dispatch(
                        campanhaActions.setInvalidsTabRefinPortabilidade(
                          true,
                          e.path || ''
                        )
                      )
                    })
                    toast.warn(
                      'Preencha todos os campos corretamente da Aba "REFIN/Portabilidade" para salvar!'
                    )
                    dispatch(campanhaActions.setActiveTabRegister(4))
                  })
              })
              .catch((err) => {
                err.inner.forEach((e: yup.ValidationError) => {
                  dispatch(
                    campanhaActions.setInvalidsTabBancos(true, e.path || '')
                  )
                })
                toast.warn(
                  'Preencha todos os campos corretamente da Aba "Bancos" para salvar!'
                )
                dispatch(campanhaActions.setActiveTabRegister(3))
              })
          })
          .catch((err) => {
            err.inner.forEach((e: yup.ValidationError) => {
              dispatch(
                campanhaActions.setInvalidsTabParametros(true, e.path || '')
              )
            })
            toast.warn(
              'Preencha todos os campos corretamente da Aba "Parâmetros" para salvar!'
            )
            dispatch(campanhaActions.setActiveTabRegister(2))
          })
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            campanhaActions.setInvalidsTabDadosGerais(true, e.path || '')
          )
        })
        toast.warn(
          'Preencha todos os campos corretamente da Aba "Dados Gerais" para salvar!'
        )
        dispatch(campanhaActions.setActiveTabRegister(1))
      })
  }

  function voltaParaSearch() {
    dispatch(campanhaActions.cleanRegister())
    cleanAllInvalids()
    dispatch(campanhaActions.setVisualizacao(false))
    dispatch(campanhaActions.setActiveTabRegister(1))
    dispatch(campanhaActions.setActiveTab('search'))
  }

  return (
    <SimpleCard className="d-flex flex-column row-gap-10">
      <Row>
        <Col md={6} className="d-flex">
          <CustomButton
            background="#662d90"
            letterSpacing={1}
            onClick={() => {
              voltaParaSearch()
            }}
          >
            Voltar
          </CustomButton>
        </Col>
        {!visualizacao && (
          <Col className="d-flex justify-content-end" md={6}>
            <CustomButton
              onClick={() => {
                onSave()
              }}
            >
              Salvar
            </CustomButton>
          </Col>
        )}
      </Row>
      <Ul id="tabs" className="nav mb-2" data-tabs="tabs">
        <Tab>
          <a
            onClick={() => {
              dispatch(campanhaActions.setActiveTabRegister(1))
            }}
            className={classNames(activeTabRegister === 1 && 'active')}
            role="button"
          >
            <span className="fw-bold">Dados Gerais</span>
          </a>
        </Tab>
        <Tab>
          <a
            onClick={() => {
              dispatch(campanhaActions.setActiveTabRegister(2))
            }}
            className={classNames(activeTabRegister === 2 && 'active')}
            role="button"
          >
            <span className="fw-bold">Parâmetros</span>
          </a>
        </Tab>
        <Tab>
          <a
            onClick={() => {
              dispatch(campanhaActions.setActiveTabRegister(3))
            }}
            className={classNames(activeTabRegister === 3 && 'active')}
            role="button"
          >
            <span className="fw-bold">Bancos</span>
          </a>
        </Tab>
        <Tab>
          <a
            onClick={() => {
              dispatch(campanhaActions.setActiveTabRegister(4))
            }}
            className={classNames(activeTabRegister === 4 && 'active')}
            role="button"
          >
            <span className="fw-bold">REFIN/Portabilidade</span>
          </a>
        </Tab>
      </Ul>
      <TabContent activeTab={activeTabRegister}>
        <TabPane tabId={1}>
          <div className="d-flex flex-column row-gap-10">
            <Campanha />
            <Localizacao />
          </div>
        </TabPane>
        <TabPane tabId={2}>
          <div className="d-flex flex-column row-gap-10">
            <Parametros />
            <MaisInformacoes />
          </div>
        </TabPane>
        <TabPane tabId={3}>
          <div className="d-flex flex-column row-gap-10">
            <BancosPagadores />
          </div>
        </TabPane>
        <TabPane tabId={4}>
          <div className="d-flex flex-column row-gap-10">
            <RefinanciamentoPortabilidade />
          </div>
        </TabPane>
      </TabContent>
    </SimpleCard>
  )
}
export default Register
